export default  [
    {id: "Mother", name: 'Mother', gender:'Female'},
    {id: "Father", name: 'Father',gender:'Male'},

    {id: "Brother", name: 'Brother',gender:'Male'},
    {id: "Sister", name: 'Sister',gender:'Female'},
    {id: "GrandMother", name: 'Grand Mother', gender:'Female'},
    {id: "GrandFather", name: 'Grand Father',gender:'Male'},

    {id: "Uncle", name: 'Uncle',gender:'Male'},
    {id: "Aunt", name: 'Aunt',gender:'Female'},
    {id: "Cousin", name: 'Guardian'},
    {id: "Guardian", name: 'Guardian'},
    {id: "Other", name: 'Other'},

]
