import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import {
    ArrayInput,
    BooleanInput, Button,
    DateInput, FormTab,
    ImageField,
    FileField,
    ImageInput,
    FileInput,
    NumberInput, SaveButton,
    SelectInput, SimpleFormIterator,
    AutocompleteInput,
    TextInput, Toolbar, required
} from "react-admin";
import familyRelationshipData from "./familyRelationshipData";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RichTextInput from "ra-input-rich-text";
import {useForm} from 'react-final-form';
import withStyles from "@material-ui/styles/withStyles";
import BackIcon from "@material-ui/icons/ArrowBack";
import NextIcon from "@material-ui/icons/ArrowForward";
import {useHistory} from 'react-router-dom';
import EthiopianDateInput from "../uiComponents/ethiopianDateInput";

const toolbarStyles = {
    // toolbar: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    // },
};

export const CustomToolbar = withStyles(toolbarStyles)(props => {
        const parent = props.record&&props.record.id?props.record.id:"create";
        const history = useHistory();
        const regex = '[^/]+(?=/$|$)'
        const tab =( +history.location.pathname.match(regex)[0])||0;
        return <Toolbar {...props}>

                {!!tab&&<Button
                    variant="contained"
                    color="secondary"
                    label="Prev" onClick={() => {

                    history.push(tab - 1 ? (tab - 1).toString() : `../${parent}`)
                }} startIcon={<BackIcon />}  />}
                {tab<(props.tabs-1)&&<Button

                    variant="contained"
                    color="primary"

                    label="Next" onClick={() => {

                    history.push(tab ? (tab + 1).toString() : `${parent}/1`)
                }} endIcon={<NextIcon />} />}

            {((props.record&&props.record.id)||tab===(props.tabs-1))&&<SaveButton/>}
            {/*<DeleteButton undoable={false} />*/}
        </Toolbar>
    })
;


export  function formatPic(value) {
    if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { src: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}



export const FamilyForm = ({getSource,scopedFormData, ...rest }) => {
    const form = useForm();
   // console.log('index',scopedFormData)
    // if(!scopedFormData){
    //     return null
    // }
    return   <Fragment  >
        <Grid container spacing={2}>
            <Grid item>
                <SelectInput {...rest} onChange={ev=>{
                    const relation= familyRelationshipData.find(e=>e.id===ev.target.value)
                    if(relation) {
                        form.change(getSource("gender"), relation.gender)
                    }
                }} fullWidth label="Relationship" source={getSource("relationship")} choices={familyRelationshipData} />

            </Grid>
            <Grid item>
                <TextInput  label="First Name" source={getSource("name")} />

            </Grid>
            <Grid item>
                <TextInput  label="Father Name"  source={getSource("fatherName")}  />

            </Grid>
        </Grid>

        <BooleanInput  defaultValue={false} label="Is Not Alive" source={getSource("notAlive")} />


        {(!scopedFormData || !scopedFormData.notAlive) &&
        <>

            <Grid container spacing={2} style={{width:"100%"}}>

                <SelectInput {...rest} fullWidth label="Gender" source={getSource("gender")} choices={[
                    {id: "Male", name: 'Male'},
                    {id: "Female", name: 'Female'},
                ]}/>
                <NumberInput {...rest}
                             defaultValue={moment().get('year') - moment(scopedFormData && scopedFormData.birthDate).get('year')}
                             onChange={ev => {
                                 const dob = moment()
                                 dob.add(-(+ev.target.value), 'year')
                                 form.change(getSource("birthDate"), dob.toDate().toDateString())
                             }} label="Age" source={getSource("age")}/>

                {/*<DateInput {...rest} onChange={ev => {*/}
                {/*    const dob = moment(ev.target.value)*/}
                {/*    form.change(getSource("age"), moment().get('year') - dob.get('year'))*/}
                {/*}} label="Birth Date" source={getSource("birthDate")} options={{*/}
                {/*    format: 'DD/MM/YYYY',*/}
                {/*    inputVariant: "filled",*/}
                {/*    views: ["year", "month", "date"],*/}
                {/*    autoOk: true,*/}
                {/*    openTo: "year"*/}
                {/*}}/>*/}


                {/*<DateInput label="Birth Date" source="birthDate" options={{ format: 'DD/MM/YYYY' }} style={{ display: 'inline', float: 'left', marginLeft: '20px' }}/>*/}
            </Grid>
            <EthiopianDateInput {...rest} onChange={ev => {
                // console.log(ev)
                const dob = moment(ev)

                form.change(getSource("age"), moment().get('year') - dob.get('year'))
            }} label="Birth Date" source={getSource("birthDate")} />
            <ImageInput  format={ formatPic } source={getSource("photo")} label="Photo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <Typography  variant="h6" gutterBottom>Address</Typography>
            <ArrayInput  label='Phone'  source={getSource("address.phones")}>
                <SimpleFormIterator  >
                    <SelectInput defaultValue={'Mobile'} label="Type"  source="phoneType" choices={[
                        {id: "Mobile", name: 'Mobile'},
                        {id: "Home", name: 'Home'},
                        {id: "Office", name: 'Office'},
                    ]}/>

                    <TextInput label="Number"  source="number" />
                </SimpleFormIterator>
            </ArrayInput>
            <Typography   variant="h6" gutterBottom>Income</Typography>
            {/*<Box display="flex" style={{width:"100%"}}>*/}
            <TextInput label="Profession"  source={getSource("profession")} />
            <NumberInput label="Salary /Monthly Income"  source={getSource("monthlyIncome")}/>
            <BooleanInput  defaultValue={false} label="isIncomeProvider" source={getSource("isIncomeProvider")}/>
            {/*</Box>*/}



            <Typography  variant="h6" gutterBottom>Education</Typography>
            <Grid container style={{width:"100%"}}  >
                <Grid item md={2}>
                    <SelectInput label="Last Education Level"  source={getSource("currentEducation.level")} choices={[
                        {id: "None", name: 'None'},
                        {id: "Kindergarten", name: 'Kindergarten'},
                        {id: "Elementary", name: 'Elementary'},
                        {id: "High School", name: 'High School'},
                        {id: "Higher Education", name: 'Higher Education'},
                    ]}/>
                </Grid>
                <Grid item md={2}>
                    <SelectInput label="Status"  source={getSource("currentEducation.status")} choices={[
                        {id: "On Going", name: 'On Going'},
                        {id: "Completed", name: 'High School'},
                        {id: "Drop Out", name: 'Drop Out'},
                    ]}/>
                </Grid>
                <Grid item md={2}>

                    <SelectInput label="School Type"   source={getSource("currentEducation.schoolType")} choices={[
                        {id: "Government", name: 'Government'},
                        {id: "Private", name: 'Private'},
                    ]}/>
                </Grid>
                <Grid item md={3}>
                    <TextInput label="School Name"  source={getSource("currentEducation.schoolName")} />
                </Grid>
                <Grid item md={3}>
                    <TextInput label="Field"  source={getSource("currentEducation.field")} />
                </Grid>
                <Grid item md={1}>
                    <NumberInput label="Grade"  source={getSource("currentEducation.grade")} />
                </Grid>
                <Grid item md={2}>
                    <NumberInput label="Distance From home (km)"  source={getSource("currentEducation.distance")} />
                </Grid>
            </Grid>
            <Typography  variant="h6" gutterBottom>Health</Typography>
            <SelectInput defaultValue={'Good'}  label="Health Condition"  source={getSource("health.generalCondition")} choices={[
                {id: "Excellent", name: 'Excellent'},
                {id: "Good", name: 'Good'},
                {id: "Fair", name: 'Fair'},
                {id: "Poor", name: 'Poor'},
                {id: "Critical", name: 'Critical'},
            ]}/>
            <TextInput  label="Health Remark"  source={getSource("health.remark")} />

        </>}
    </Fragment>
}
export const PlaceForm = props => {
    const form = useForm();
    const orphanages = [
        { name: 'Zewditu', id: 'Zewditu', address:{city:"Addis Ababa", subcity:"Gullele", houseNumber:"", woreda:"", locationText:"Qusquam Mariam, Entoto near to Entoto Park shiromeda gate"}, photo:"http://res.cloudinary.com/finado/image/upload/v1621326799/photos/fmo1dbjgxznrekhewxkz.jpg" },
     //   { name: 'Other', id: 'Other', address:{city:"Addis Ababa", subcity:"Gullele", houseNumber:"", woreda:"", locationText:"Qusquam Mariam, Entoto near to Entoto Park shiromeda gate"}, photo:"" },

    ];
    return   <FormTab {...props}   label="Place">

        <SelectInput label="Place Type"  source="place.type" choices={[
            {id: "Rental", name: 'Rental'},
            {id: "Private", name: 'Private'},
            {id: "Kebele", name: 'Kebele'},
            {id: "Orphanage", name: 'Orphanage'},
        ]}/>
        {props.formData.place && props.formData.place.type ==='Orphanage' && <AutocompleteInput allowEmpty source="place.name" label="Place Name" choices={orphanages}  onCreate={() => {
            const newCategoryName = prompt('Enter a new Place');
            const newCategory = { id: newCategoryName.toLowerCase(), name: newCategoryName };
            orphanages.push(newCategory);
            return newCategory;
        }} onChange={id=>{
            const item = orphanages.find(o=>o.id===id)
            if(item){
                form.change('place.address',item.address)
                form.change('place.photo',item.photo)
            }

        }} />}

        <TextInput label="City"  source="place.address.city" />
        <TextInput label="Sub City"  source="place.address.subcity" />
        <TextInput label="Woreda"  source="place.address.woreda" />
        <TextInput label="House Number"  source="place.address.houseNumber" />
        <TextInput label="Location Text"  source="place.address.locationText" />
        <ImageInput format={ formatPic } source="place.photo" label="Picture" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput label="Remark"  source="place.address.remark" />

    </FormTab>
}
export  const PersonalForm = props => {
    const form = useForm();
    const {formData} = props;


    return  <FormTab {...props}  label="Personal">

        <TextInput label="First Name" source="name" style={{ display: 'inline', float: 'left'}} validate={[required()]} />
        <TextInput label="Father Name"  source="fatherName" style={{ display: 'inline', float: 'left', marginLeft: '20px' } } validate={[required()]} />
        <TextInput label="Grand Father Name" source="gFatherName" />
        <SelectInput source="gender" fullWidth choices={[
            {id: "Male", name: 'Male'},
            {id: "Female", name: 'Female'},
        ]} style={{ display: 'inline', width:"25%", float: 'left'}}/>

  <NumberInput source='age' onChange={ev=>{
      const dob = moment("2021-01-01")
      dob.add(-(+ev.target.value), 'year')
      form.change("birthDate", dob.toDate().toDateString())
  }} />

        {/*<DateInput source="birthDate"  options={{ format: 'DD/MM/YY' }} style={{ display: 'inline', width:"25%", float: 'left'}} />*/}
   <EthiopianDateInput label="Birth Date" source="birthDate" onChange={ev=>{
       const dob = moment(ev)

       form.change("age", moment().get('year') - dob.get('year'))
   }} />

       {/*<AutocompleteInput source="placeOfBirth" choices={[*/}
        {/*    { id: 'Addis Ababa', name: 'Addis Ababa' },*/}
        {/*   ]} />*/}
        <TextInput defaultValue="Addis Ababa"  source="placeOfBirth" />
        {/*<DateTimeInput source="birthDate"  options={{ format: 'DD/MM/YYYY' }}  />*/}
        <ImageInput format={ formatPic } source="photo" label="Photo" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="src" title="title" />
        </ImageInput>

        <Typography variant="h6" gutterBottom>Health</Typography>
        {/*<TextInput label="Health Condition"  source="health.generalCondition" />*/}
        <Box display="flex" style={{width:"100%"}}>
            <Box flex={3} mr="0.5em">
                <SelectInput defaultValue={'Good'} label="Health Condition"  source="health.generalCondition" choices={[
                    {id: "Excellent", name: 'Excellent'},
                    {id: "Good", name: 'Good'},
                    {id: "Fair", name: 'Fair'},
                    {id: "Poor", name: 'Poor'},
                    {id: "Critical", name: 'Critical'},
                ]}/>
            </Box>
            <Box flex={9} ml="0.5em">
                <TextInput multiline fullWidth  label="Health Remark"  source="health.remark" />
            </Box>
        </Box>


        <Typography variant="h6" gutterBottom>Current Education</Typography>
        <Grid container style={{width:"100%"}}  >
            <Grid item md={3}>
                <SelectInput label="Education Level"  source="currentEducation.level" choices={[
                    {id: "None", name: 'None'},
                    {id: "Kindergarten", name: 'Kindergarten'},
                    {id: "Elementary", name: 'Elementary'},
                    {id: "High School", name: 'High School'},
                    {id: "Higher Education", name: 'Higher Education'},
                    {id: "Other", name: 'Other'},
                ]}/>
            </Grid>
            <Grid item md={2}>

                <SelectInput label="School Type"   source="currentEducation.schoolType" choices={[
                    {id: "Government", name: 'Government'},
                    {id: "Private", name: 'Private'},
                ]}/>
            </Grid>
            <Grid item md={3}>
                <TextInput label="School Name"  source="currentEducation.schoolName" />
            </Grid>
            {(formData?.currentEducation?.level==="Higher Education"||formData?.currentEducation?.level==="Other")&&<Grid item md={3}>
                <TextInput label="Field" source="currentEducation.field"/>
            </Grid>}
            {(formData?.currentEducation?.level==="Higher Education"||formData?.currentEducation?.level==="Other")&&<Grid>
                <NumberInput label="Study Duration" source="currentEducation.studyDuration"/>
            </Grid>}
            {(formData?.currentEducation?.level==="Higher Education"||formData?.currentEducation?.level==="Other")&&<Grid>
                <Grid item md={2}>

                    <SelectInput label="Qualification Level"   source="currentEducation.qualificationLevel" choices={[
                        {id: "Certificate", name: 'Certificate'},
                        {id: "Diploma", name: 'Diploma'},
                        {id: "Degree", name: 'Degree'},
                        {id: "Masters", name: "Master's"},
                        {id: "Doctorate", name: 'Doctorate'},
                    ]}/>
                </Grid>
            </Grid>}
            {(formData?.currentEducation?.level==="Higher Education"||formData?.currentEducation?.level==="Other")&&<Grid>
                <Grid item md={2}>

                    <SelectInput label="Duration Period"   source="currentEducation.durationPeriod" choices={[
                        {id: "Month", name: 'Month'},
                        {id: "Year", name: 'Year'},
                    ]}/>
                </Grid>
            </Grid>}
            <Grid item md={2}>
                <NumberInput label="Current Grade/Level"  source="currentEducation.grade" />
            </Grid>
            <Grid item md={2}>
                <NumberInput label="Distance From home (km)"  source="currentEducation.distance" />
            </Grid>
        </Grid>
        <Typography  variant="h6" gutterBottom>Contact Info</Typography>


        <ArrayInput  label='Phone' label='Phones' source="address.phones">
            <SimpleFormIterator  >
                <TextInput label="Number"  source="number" />
            </SimpleFormIterator>
        </ArrayInput>
        <TextInput lable='Email' label='Email' source='address.email'/>

    </FormTab>
}
export const StoryFrom = props => {
    return  <FormTab {...props}   label="Story">
        <RichTextInput label="Story"  source="story.story" />
        <TextInput label="Wants to be" source="story.dream"/>

        <ArrayInput label='Attachments'  source="story.files">
            <SimpleFormIterator  >
                {/*<TextInput source="filePath" />*/}
                <FileInput source="filePath" label="File"  placeholder={<p>Drop your file here</p>}>
                    <FileField source="src" title="title" />
                </FileInput>
                <SelectInput label="Type"  source="type" choices={[
                    {id: "SubcityFile", name: 'Subcity File'},
                    {id: "Certificate", name: 'School Certificate'},
                    {id: "Other", name: 'Other'},
                ]}/>

                <TextInput label='Title' source="title" />
                <DateInput label='Date' source="date"  options={{ format: 'DD/MM/YYYY' }}/>
                <TextInput multiline label='Description' source="description" />



            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label='Photos'  source="story.photos">
            <SimpleFormIterator  >
                {/*<TextInput source="filePath" />*/}
                <ImageInput format={ formatPic } source="filePath" label="Photo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                    <ImageField source="src" title="title" />
                </ImageInput>

                    <TextInput label='Title' source="title" />
                    <DateInput label='Date' source="date"  options={{ format: 'DD/MM/YYYY' }}/>
                    <TextInput multiline label='Description' source="description" />



            </SimpleFormIterator>
        </ArrayInput>

        {/*<BooleanInput label="Government Approved" source="story.governmentApproved" />*/}
    </FormTab>
}
