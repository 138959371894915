// in App.js
import * as React from 'react';
import {Component, useEffect, useState} from 'react';
import buildGraphQLProvider from 'ra-data-graphql-simple';
import { Admin, Resource } from 'react-admin';
// import {ApplicationList, ApplicationShow} from "./entities/application";
import graphDataProvider from "./helpers/graphDataProvider";
import addUploadCapabilities from "./helpers/fileUpload";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// import { PostCreate, PostEdit, PostList } from './posts';
import ApplicationEntity from './entities/application'
import RecordEntity from './entities/record'
import PostEntity from './entities/posts'
import authProvider from "./helpers/authProvider";
import MyLayout from "./uiComponents/layout";
import Themes from "./themes";
import PeopleIcon from "@material-ui/icons/PeopleOutline"
import HistoryIcon from "@material-ui/icons/InputOutlined"
import PostsIcon from "@material-ui/icons/LocalPostOfficeOutlined"
import VisitEntity from "./entities/visit"
import  VisitIcon from "@material-ui/icons/ViewAgendaOutlined"
import jsonServerProvider from "ra-data-json-server"
class App extends Component {
    constructor() {
        super();
        this.state = { dataProvider: null };
    }
    componentDidMount() {
        graphDataProvider
            .then(dataProvider => this.setState({ dataProvider }));
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return <div>Loading</div>;
        }

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <Admin theme={Themes.default} layout={MyLayout} authProvider={authProvider} dataProvider={addUploadCapabilities(dataProvider)}>
                {
                    permissions=> [
                        <Resource icon={HistoryIcon}  name="Application" {...ApplicationEntity}  />,
                        <Resource icon={PeopleIcon} name="Record" {...RecordEntity}  />,
                        // <Resource icon={VisitIcon} name="Visit" {...VisitEntity}  />,
                        (permissions.includes('handler') ||  permissions.includes('admin'))
                            ?    <Resource icon={VisitIcon} name="Visit"     {...VisitEntity} create={(permissions.includes('handler')||permissions.includes('admin')) ? VisitEntity.create : null}  />
                            : null,
                        <Resource icon={PostsIcon} name="Post" {...PostEntity}  />,
                    ]
                }
            </Admin>
             </MuiPickersUtilsProvider>
        );
    }
}
// const App = () => {
//
//     const [dataProvider,setDataProvider] = useState(jsonServerProvider('http://jsonplaceholder.typicode.com'))
//     // useEffect(() => {
//     //     graphDataProvider
//     //          .then(dp => setDataProvider(dp));
//     // }, [dataProvider])
//     // if (!dataProvider) {
//     //         return <div>Loading</div>;
//     //     }
//     return <MuiPickersUtilsProvider utils={MomentUtils}>
//         <Admin theme={Themes.default} layout={MyLayout} authProvider={authProvider} dataProvider={addUploadCapabilities(dataProvider)}>
//                         <Resource icon={HistoryIcon}  name="Application" {...ApplicationEntity}  />
//                         <Resource icon={PeopleIcon} name="Record" {...RecordEntity}  />
//                   </Admin>
//     </MuiPickersUtilsProvider>
// }
export default App;
