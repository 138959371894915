import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import React, {useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

const ImagesWithLightBox = ({photos,initialIndex}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)
    const images = photos.map(p=>p.filePath);
    const [photoIndex, setPhotoIndex] = useState(initialIndex || 0)
    if(!photos)
    {
        return null
    }
    return (


        <div>


                    <Grid container spacing={2}>
                        {photos.filter(p=>p.filePath && p.filePath!=='').map((p,i)=><Grid  item>
                            <Card>
                                <CardActionArea onClick={e => {
                                    setIsOpen(true)
                                    setPhotoIndex(i)
                                }}>
                                    <CardMedia
                                        className={classes.media}
                                        image={p.filePath}
                                        title={p.title}
                                    />
                                </CardActionArea>
                                <CardContent>
                                    {p.title}
                                    {p.description}
                                </CardContent>

                            </Card>
                        </Grid>)}
                    </Grid>

            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                       setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}

        </div>
    );
};

export default ImagesWithLightBox;
