import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import React, {useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
const useStyles = makeStyles({
    root: {
        width: 200,
    },
    media: {
        height: 200,
        width: 200,
    },
});
const PictureWithLightBox = ({title,picture,className}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)
    if(!picture) return null
    return (
        <div>
           <Card className={classes.root}>
               <CardActionArea onClick={e => {
                   setIsOpen(true)
                  }}>
               <CardMedia
                   image={picture}
                   title={title}
                   className={classes.media}
               />
               </CardActionArea>
           </Card>
            {isOpen && (
                <Lightbox
                    mainSrc={picture}

                    onCloseRequest={() => setIsOpen(false)}

                />
            )}
        </div>
    );
};

export default PictureWithLightBox;
