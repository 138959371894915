 const authProvider = {
    login: async ({ username, password }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_AUTH_API}/email`, {
                method: 'POST',
                headers: {      'Accept': 'application/json',      'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: username, password: password})

            });

            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

        const {token, user:{roles}} = await response.json();

        localStorage.setItem('token', token);
        localStorage.setItem('roles', JSON.stringify(roles));
    }
    catch (e) {
        return  Promise.reject("Invalid login")

    }
        // if(username==="admin" && password ==="finado1!")
        // {
        //     localStorage.setItem('token', 'finadoToken')
        //     return Promise.resolve()
        // }
        // else
        //     return Promise.reject()



    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
     checkAuth: () => localStorage.getItem('token')
         ? Promise.resolve()
         : Promise.reject(),
     checkError: (error) => {

         const status = error.networkError && error.networkError.statusCode;
        // console.log(status)
         if (status === 401 || status === 403) {
             localStorage.removeItem('token');
             return Promise.reject();
         }
         return Promise.resolve();
     },
     getPermissions: params =>{
         const roles = JSON.parse(localStorage.getItem('roles'));
         return roles ? Promise.resolve(roles) : Promise.reject();
     },
};

export default authProvider
