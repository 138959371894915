import {
    AutocompleteInput,
    Create,
    RichTextField,
    Edit,
    Show,
    Datagrid,
    DateField,
    FunctionField,
    EditButton,
    Filter,
    ImageField,
    List,
    ReferenceInput,
    ShowButton,
    TextField,
    ReferenceField,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    FormTab,
    SelectInput,
    ArrayField,
    FileField,
    Tab,
    ArrayInput,
    SimpleFormIterator, FileInput
} from "react-admin";
import React from "react";
import RichTextInput from 'ra-input-rich-text';
import LightBoxField from "../uiComponents/lightBoxField";
const PostFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search by Registration ID" source="registrationId" alwaysOn />*/}

        <ReferenceInput filterToQuery={searchText => {}} emptyText="All Records" label="Search by Id" source="recordId" reference="Record" alwaysOn  perPage={1000} allowEmpty>
            <AutocompleteInput  optionText={(record)=> record.registrationId?`${record.name} ${record.fatherName} - ${record.registrationId}`:"All Records"} allowEmpty options={{ type: 'search' }}/>
        </ReferenceInput >

        <SelectInput alwaysOn emptyText="All Types"   source="type" label='Post Type'  choices={[
            {id: "Feedback", name: 'Feedback'},
            {id: "News", name: 'News'},
            {id: "Event", name: 'Event'},
            {id: "Certificate", name: 'Certificate'},
            {id: "Achievement", name: 'Achievement'},
            {id: "Other", name: 'Other'},


        ]}/>
    </Filter>
)

const list= props => {

        return <List perPage={100}  filters={<PostFilter/>}   {...props}    sort={{ field: 'date', order: 'DESC' }}>
            <Datagrid rowClick=''>
                <ReferenceField label="Finado Child" source="recordId" reference="Record">
                    <FunctionField label="Name" render={record => record ? record.registrationId+"-"+record.name+" "+record.fatherName : null} />
                </ReferenceField>
                <TextField source="type"  />
                <TextField source="title" sortable={true} />
                <DateField source="date" />
                <LightBoxField source={"files"} label={"File"}/>
                <ShowButton/>
                <EditButton/>

            </Datagrid>
        </List>
    }
;

const create = ({permissions,...props}) => <Create {...props} >
    <SimpleForm redirect='list' initialValues={{date: new Date().toString()}}>
        <ReferenceInput filterToQuery={searchText => {}}   label="Finado Child" source="recordId" reference="Record" perPage={1000}>
            <AutocompleteInput    optionText={choice=>`${choice.name} ${choice.fatherName} - ${choice.registrationId}`} />
        </ReferenceInput>
        <DateInput source="date"  />
        <SelectInput defaultValue={"Other"}   source="type"  choices={[
            {id: "Feedback", name: 'Feedback'},
            {id: "News", name: 'News'},
            {id: "Event", name: 'Event'},
            {id: "Certificate", name: 'Certificate'},
            {id: "Achievement", name: 'Achievement'},
            {id: "Other", name: 'Other'},


        ]}/>
        <TextInput source="title" validate={[required()]} fullWidth />
        <RichTextInput source="body" />
        <ArrayInput label='Attachments'  source="files">
            <SimpleFormIterator  >
                {/*<TextInput source="filePath" />*/}
                <FileInput source="filePath" label="File"  placeholder={<p>Drop your file here</p>}>
                    <FileField source="src" title="title" />
                </FileInput>

                <TextInput label='Title' source="title"   />




            </SimpleFormIterator>
        </ArrayInput>

    </SimpleForm>
</Create>

const edit = ({permissions,...props}) => <Edit {...props}>
    <SimpleForm redirect='list'>
        <ReferenceInput filterToQuery={searchText => {}}   label="Finado Child" source="recordId" reference="Record" perPage={1000}>
            <AutocompleteInput    optionText={choice=>`${choice.name} ${choice.fatherName} - ${choice.registrationId}`} />
        </ReferenceInput>
        <DateInput source="date" defaultValue={new Date()} />
        <SelectInput defaultValue={"Other"}   source="type"  choices={[
            {id: "Achievement", name: 'Achievement'},
            {id: "Other", name: 'Other'},


        ]}/>
        <TextInput source="title" validate={[required()]} fullWidth />
        <RichTextInput source="body" />
        <ArrayInput label='Attachments'  source="files">
            <SimpleFormIterator  >
                {/*<TextInput source="filePath" />*/}
                <FileInput source="filePath" label="File"  placeholder={<p>Drop your file here</p>}>
                    <FileField source="src" title="title" />
                </FileInput>

                <TextInput label='Title' source="title"   />




            </SimpleFormIterator>
        </ArrayInput>

    </SimpleForm>
</Edit>

const show = ({permissions,...props}) => <Show {...props}>
  <SimpleForm>
      <DateField source="date"/>
      <ReferenceField  source="recordId" reference="Record">
          <TextField source="fullName" />
      </ReferenceField>
      <TextField source="title"/>
      <RichTextField source="body"/>
      <ArrayField label='Attachments'  source="files">



          <Datagrid bulkActionButtons={false}>


              <TextField label='Title' source="title"   />
              <FileField source="filePath" title="View/Download"  label="File"/>
              <LightBoxField label="Photo" source="filePath"/>

          </Datagrid>







      </ArrayField>
  </SimpleForm>
</Show>

export  default {list,create,edit,show}
