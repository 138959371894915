import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import {DateTimeInput } from 'react-admin-date-inputs2';
import {
    Create,
    FormDataConsumer,
    Datagrid,
    DateField,
    Edit,
    List,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    DateInput,
    ImageInput,
    BooleanInput,
    NumberInput,
    Toolbar,
    DeleteButton,
    ImageField,
    NumberField,
    ArrayField,
    Filter,
    AutocompleteInput,
    RichTextField,
    SaveButton,
    Button, ShowButton, EditButton


} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import RecordSummery from "../components/recordSummery";
import ApplicationActions from "../components/applicationActions";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
//
// import Card from "@material-ui/core/Card";
// import {uploadFile} from "../helpers/fileUpload";


// export const ReviewToolbar = props => (
//     <Toolbar {...props} >
//
//         <DeleteButton redirect={props.redirect}/>
//
//     </Toolbar>
// );

import { makeStyles } from '@material-ui/core/styles';
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import familyRelationshipData from "../helpers/familyRelationshipData";
import moment from "moment";
import record from "./record";
import {FamilyForm, PersonalForm, PlaceForm, StoryFrom, CustomToolbar} from "../helpers/commonForms";
import withStyles from "@material-ui/styles/withStyles";


const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        width: 50,
        height: 50,
    }
}));




const ApplicationFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn  source="status" label='Status' choices={[
            {id: "Pending", name: 'Pending'},
            {id: "Waiting", name: 'Waiting'},
            {id: "Rejected", name: 'Rejected'},
            {id: "Accepted", name: 'Accepted'},
        ]}/>
        <SelectInput alwaysOn  source="placeType" label='Place Type' choices={[
            {id: "Orphanage", name: 'Orphanage'},
            {id: "Rental", name: 'Rental'},
            {id: "Private", name: 'Private'},
            {id: "Kebele", name: 'Kebele'},

        ]}/>

        <ReferenceInput label="Applicant Name" source="_id" reference="Application" perPage={1000}>
            <AutocompleteInput  optionText="name" options={{ type: 'search' }}/>
        </ReferenceInput >

    </Filter>
)


export const ApplicationList= props => {
    const imageFieldClasses = useImageFieldStyles();
     return   <List perPage={100}  filterDefaultValues={{ status: 'Pending' }}  {...props} filters={<ApplicationFilter/>}>
            <Datagrid rowClick='show'>
                {/*<TextField source="id" />*/}
                <ImageField label="Photo" source="photo" classes={imageFieldClasses} />
                <TextField source="fullName"/>

                <DateField label="Applied On" source="date"/>
                <TextField source="status"/>
                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </List>
    }
;

export const ApplicationShow = props =>
    <Show {...props}  >
        <TabbedShowLayout >
            <Tab label="Summary">


                        <RecordSummery/>


                        <ApplicationActions/>




            </Tab>
            <Tab label="Personal">

                <TextField label="First Name" source="name" />
                <TextField label="Father Name"  source="fatherName"  />
                <TextField label="Grand Father Name" source="gFatherName" />
                <TextField  source="gender" />
                <DateField label="Birth Date" source="birthDate"/>
                <ImageField label="Photo"  source="photo" />

                <TextField label="Health Condition"  source="health.generalCondition"  />
                <TextField label="Health Remark"  source="health.remark"/>

                <TextField label="Level"  source="currentEducation.level"/>
                <TextField label="School Type"   source="currentEducation.schoolType" />
                <NumberField label="Grade"  source="currentEducation.grade"/>
                <TextField label="Distance From home (in Km)"  source="currentEducation.distance" />

            </Tab>
            <Tab label="Families">
                <ArrayField label='Family Member'  source="families">
                    <Datagrid>
                        <TextField  source="relationship" />
                        <TextField label="First Name" source="name" />
                        {/*<TextField  source="gender" />*/}
                        <DateField label="Birth Date" source="birthDate"/>
                        <ImageField label="Photo"  source="photo" />

                        <TextField label="Health Condition"  source="health.generalCondition"  />
                        <TextField label="Health Remark"  source="health.remark"/>
                        <TextField label="Phone"  source="address.phones[0].number"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Place">
                <TextField label="Place Name" source="place.name" />
                <TextField  label="City"  source="place.address.city" />
                <TextField  label="Sub City"  source="place.address.subcity" />
                <TextField label="Woreda"  source="place.address.woreda" />
                <TextField  label="House Number"  source="place.address.houseNumber" />
                <TextField  label="Location Text"  source="place.address.locationText" />
                <TextField label="Remark"  source="place.address.remark" />
            </Tab>
            <Tab label="Story">
                <RichTextField label="Story"  source="story.story" />
                <TextField label="Government Approved" source="story.governmentApproved" />
                <ArrayField label='Photos'  source="story.photos">
                    <Datagrid>
                        <TextField  source="title" />

                        <ImageField label="Photo"  source="filePath" />

                        <TextField label="Description"  source="description"  />

                    </Datagrid>
                </ArrayField>

            </Tab>
        </TabbedShowLayout>
    </Show>

// const CustomToolbar = withStyles(toolbarStyles)(props => {
//     const parent = props.record&&props.record.id?props.record.id:"create";
//     const history = useHistory();
//     const regex = '[^/]+(?=/$|$)'
//     const tab =( +history.location.pathname.match(regex)[0])||0;
//         return <Toolbar {...props}>
//             <div>
//             {!!tab&&<Button
//                 variant="contained"
//                 color="secondary"
//                 label="Prev" onClick={() => {
//
//                 history.push(tab - 1 ? (tab - 1).toString() : `../${parent}`)
//             }} startIcon={<BackIcon />}  />}
//             {tab<4&&<Button
//                 variant="contained"
//                 color="primary"
//
//                 label="Next" onClick={() => {
//
//                 history.push(tab ? (tab + 1).toString() : `${parent}/1`)
//             }} endIcon={<NextIcon />} />}
//             </div>
//             {((props.record&&props.record.id)||tab===4)&&<SaveButton/>}
//             {/*<DeleteButton undoable={false} />*/}
//         </Toolbar>
//     })
// ;

const ApplicationForm = props =>  <TabbedForm toolbar={<CustomToolbar tabs={5} {...props} />}  {...props} redirect='list'  >
    <FormDataConsumer>
        {formDataProps => (
            <PersonalForm {...formDataProps}/>
        )}
    </FormDataConsumer>

        <FormTab   label="Family">
            <ArrayInput label='Family Member'  source="families">
                <SimpleFormIterator  >


                <FormDataConsumer>
                {formDataProps => (
                    <FamilyForm {...formDataProps}  />
                )}
                </FormDataConsumer>


                    {/*<Box display="flex" style={{width:"100%"}}>*/}
                    {/*    */}
                    {/*<TextInput label="Health Condition"  source="health.generalCondition" />*/}
                    {/*<TextInput label="Health Remark"  source="health.remark" />*/}
                    {/*</Box>*/}

                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>


    <FormDataConsumer>
        {formDataProps => (
        <PlaceForm {...formDataProps}/>
        )}
    </FormDataConsumer>

    <StoryFrom/>
        <FormTab   label="Application">
            <DateInput defaultValue={moment().format("MM-DD-YYYY")} label="Date" source="date" options={{ format: 'DD/MM/YYYY' }}/>
            <TextInput source="processedBy" />
        </FormTab>

    </TabbedForm>


export const ApplicationEdit = props => (
    <Edit {...props}>
        <ApplicationForm/>
    </Edit>
);

export const ApplicationCreate = props => (
    <Create {...props}>
        <ApplicationForm />
    </Create>
)

export  default {list:ApplicationList,edit:ApplicationEdit,create:ApplicationCreate,show:ApplicationShow}
