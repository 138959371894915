import React from "react";

import {
    Create,
    Datagrid,
    DateField,
    BooleanField,
    Edit,
    List,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    SimpleForm,
    RichTextField,
    FileField,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    DateInput,
    ImageInput,
    BooleanInput,
    NumberInput,
    Toolbar,
    DeleteButton,
    ImageField,
    NumberField,
    ArrayField,
    Filter, FormDataConsumer, AutocompleteInput,
    ShowButton,
    EditButton,
    FunctionField,

} from 'react-admin';
import RecordSummery from "../components/recordSummery";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RichTextInput from "ra-input-rich-text";
// import { DateInput } from 'react-admin-date-inputs2';
import DialogContent from "@material-ui/core/DialogContent";
import {CustomToolbar, FamilyForm, PersonalForm, PlaceForm, StoryFrom} from "../helpers/commonForms";
import LightBoxField from "../uiComponents/lightBoxField";
import RecordActions from "../components/recordActions";
import moment from "moment";

const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        width: 50,
        height: 50,
    }
}));



// export const ReviewToolbar = props => (
//     <Toolbar {...props} >
//
//         <DeleteButton redirect={props.redirect}/>
//
//     </Toolbar>
// );


const RecordFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search by Registration ID" source="registrationId" alwaysOn />*/}

        <ReferenceInput filterToQuery={searchText => {}} emptyText="All Records" label="Search by Id" source="_id" reference="Record" alwaysOn  perPage={1000} allowEmpty>
            <AutocompleteInput  optionText={(record)=> record.registrationId?record.registrationId:"All Records"} allowEmpty options={{ type: 'search' }}/>
        </ReferenceInput >

        <ReferenceInput filterToQuery={searchText => {}} emptyText="All Records" label="Search by Name" source="_id" reference="Record" alwaysOn  perPage={1000} allowEmpty>
            <AutocompleteInput  optionText={(record)=> record.name?(record.name||'')+" "+(record.fatherName?record.fatherName:''):"All Records"} allowEmpty options={{ type: 'search' }}/>
        </ReferenceInput >

        <SelectInput alwaysOn emptyText="All Places"   source="placeType" label='Place Type' choices={[
            {id: "Orphanage", name: 'Orphanage'},
            {id: "Rental", name: 'Rental'},
            {id: "Private", name: 'Private'},
            {id: "Kebele", name: 'Kebele'},

        ]}/>
        <BooleanInput label="Official" source="official" />
        <SelectInput emptyText="All"   source="status" label='Status' choices={[
            {id: "Active", name: 'Active'},
            {id: "Discontinued", name: 'Discontinued'},


        ]}/>
    </Filter>
)



export const RecordList= props => {
    const imageFieldClasses = useImageFieldStyles();
        return <List perPage={100}   {...props}  filters={<RecordFilter/>}     sort={{ field: 'registrationId', order: 'ASC' }}>
            <Datagrid rowClick='show'>
                <TextField source="registrationId"  />
                <TextField source="fullName" sortable={true} />
                <DateField label="Birth Date" source="birthDate" sortable={true} />
                <FunctionField label="Age" sortable={true}  render={record => moment().diff(moment(record.birthDate),"years")} />
                <ImageField label="Photo" source="photo" classes={imageFieldClasses} sortable={false} />
                <DateField label="Registered On" source="date" sortable={true} />

                <ShowButton/>
                <EditButton/>

            </Datagrid>
        </List>
    }
;

export const RecordShow = props =>
    <Show {...props}  >
        <TabbedShowLayout >
            <Tab label="Summary">
                <RecordSummery/>
<RecordActions/>


            </Tab>
            <Tab label="Personal">
                <TextField label="First Name" source="name" />
                <TextField label="Father Name"  source="fatherName"  />
                <TextField label="Grand Father Name" source="gFatherName" />
                <TextField  source="gender" />
                <DateField label="Birth Date" source="birthDate"/>
                {/*<ImageField label="Photo"  source="photo" />*/}
                <LightBoxField label="Photo" source="photo"/>

                <TextField label="Health Condition"  source="health.generalCondition"  />
                <TextField label="Health Remark"  source="health.remark"/>

                <TextField label="Level"  source="currentEducation.level"/>
                <TextField label="School Type"   source="currentEducation.schoolType" />
                <NumberField label="Grade"  source="currentEducation.grade"/>
                <TextField label="Distance From home"  source="currentEducation.distance" />

            </Tab>
            <Tab label="Families">
                <ArrayField label='Family Member'  source="families">
                    <Datagrid>
                        <TextField  source="relationship" />
                        <TextField label="First Name" source="name" />
                        {/*<TextField  source="gender" />*/}
                        <DateField label="Birth Date" source="birthDate"/>
                        <LightBoxField label="Photo" source="photo"/>

                        <TextField label="Health Condition"  source="health.generalCondition"  />
                        <TextField label="Health Remark"  source="health.remark"/>
                        <TextField label="Phone"  source="address.phones[0].number"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Place">
                <TextField label="Place Name" source="place.name" />
                <TextField  label="City"  source="place.address.city" />
                <TextField  label="Sub City"  source="place.address.subcity" />
                <TextField label="Woreda"  source="place.address.woreda" />
                <LightBoxField label="Photo" source="place.photo"/>
                <TextField  label="House Number"  source="place.address.houseNumber" />
                <TextField  label="Location Text"  source="place.address.locationText" />
                <TextField label="Remark"  source="place.address.remark" />
            </Tab>
            <Tab label="Story">
                <RichTextField label="Story"  source="story.story" />
                {/*<TextField label="Government Approved" source="story.governmentApproved" />*/}
                <ArrayField label='Attachments'  source="story.files">
                    <Datagrid>
                        <TextField  source="type" />
                        <TextField  source="title" />

                        <TextField label="Description"  source="description"  />
                        <FileField label="File" source="filePath"  title="title"  />

                    </Datagrid>
                </ArrayField>
                <ArrayField label='Photos'  source="story.photos">
                    <Datagrid>
                        <TextField  source="title" />

                        <LightBoxField label="Photo" source="filePath"/>

                        <TextField label="Description"  source="description"  />

                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab    label="Registration">
                <DateField source="date" />
                <TextField  label="Registration ID" source="registrationId" />
                <BooleanField label="Government Approved" source="official" />
                <Typography  variant="h6" gutterBottom>Sponsor Info</Typography>
                <TextField label="Name" source="sponsor.name" />
                <TextField label="FatherName" source="sponsor.fatherName" />
                <TextField label="Phone" source="sponsor.address.phones[0].number" />
                <TextField label="Email" source="sponsor.address.email" />
                <TextField source="processedBy" />
            </Tab>
        </TabbedShowLayout>
    </Show>
function formatPic(value) {
    if (!value ||  typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
        return { src: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}
const RecordForm = props =>

    <TabbedForm toolbar={<CustomToolbar tabs={5} {...props} />} {...props} redirect='list'  >
        <FormDataConsumer>
            {formDataProps => (
                <PersonalForm {...formDataProps}/>
            )}
        </FormDataConsumer>

        <FormTab   label="Family">
            <ArrayInput label='Family Member'  source="families">
                <SimpleFormIterator  >


                    <FormDataConsumer>
                        {formDataProps => (
                            <FamilyForm {...formDataProps}  />
                        )}
                    </FormDataConsumer>


                    {/*<Box display="flex" style={{width:"100%"}}>*/}
                    {/*    */}
                    {/*<TextInput label="Health Condition"  source="health.generalCondition" />*/}
                    {/*<TextInput label="Health Remark"  source="health.remark" />*/}
                    {/*</Box>*/}

                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>


        <FormDataConsumer>
            {formDataProps => (
                <PlaceForm {...formDataProps}/>
            )}
        </FormDataConsumer>

        <StoryFrom/>

    <FormTab   label="Registration">

        <TextInput source="registrationId" />
        <DateInput options={{ format: 'DD/MM/YYYY' }} source="date" />
        <BooleanInput defaultValue={true} label="Government Approved"  source="official" />
        {!props.create&&<SelectInput defaultValue={"Active"} source="status" label='Status' choices={[
            {id: "Active", name: 'Active'},
            {id: "Discontinued", name: 'Discontinued'},
        ]}/>}
        <Typography variant="h6" gutterBottom>Sponsor Info</Typography>
        <TextInput label="Name" source="sponsor.name" />
        <TextInput label="FatherName" source="sponsor.fatherName" />
        <TextInput label="Phone" source="sponsor.address.phones[0].number" />
        <TextInput label="Email" source="sponsor.address.email" />
        <TextInput source="processedBy" />
    </FormTab>

</TabbedForm>

export const RecordEdit = props => (
    <Edit {...props}>
        <RecordForm/>
    </Edit>
);

export const RecordCreate = props => (
    <Create {...props}>
        <RecordForm create />
    </Create>
)

export  default {list:RecordList,edit:RecordEdit,create:RecordCreate,show:RecordShow}
