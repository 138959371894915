import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import React, {useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";

const AvatarWithLightBox = ({title,picture,className}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            <Avatar onClick={()=>setIsOpen(true)} alt={title} src={picture}  className={className}  />
            {isOpen && (
                <Lightbox
                    mainSrc={picture}

                    onCloseRequest={() => setIsOpen(false)}

                />
            )}
        </div>
    );
};

export default AvatarWithLightBox;
