import axios from 'axios'
import {CREATE, UPDATE} from "react-admin";
import moment from "moment";
export function uploadFile(file,uploadPreset,fileName, cloudName='finado') {


    let formData = new FormData();
    // formData.append("api_key", '');
    formData.append("file", file);
    console.log({fileName})
    if(fileName) {

        formData.append("public_id", fileName);
    }
    // formData.append("timestamp", timeStamp);
    formData.append("upload_preset", uploadPreset);

  return   axios
        .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)

}

export default (dataProvider) => (fetchType, resource, params) => {
    const promises = []
    if ((resource === "Application" || resource === "Record"|| resource === "Visit" || resource === "Post"  ) && (fetchType === CREATE || fetchType === UPDATE)) {
        const {data, ...rest_params} = params;

        if(params.data.photo && params.data.photo.rawFile instanceof File) {
           promises.push(uploadFile(data.photo.rawFile, 'photos').then(res => {
               params.data.photo = res.data.secure_url
                // return dataProvider(fetchType, resource, {
                //     ...rest_params,
                //     data: {...data, photo: res.data.url},
                // });
            })
           )
         }

        if(params.data.place && params.data.place.photo && params.data.place.photo.rawFile instanceof File) {
            promises.push(uploadFile(data.place.photo.rawFile, 'photos').then(res => {
                    params.data.place.photo = res.data.secure_url
                    // return dataProvider(fetchType, resource, {
                    //     ...rest_params,
                    //     data: {...data, photo: res.data.url},
                    // });
                })
            )
        }

        if(params.data.story && params.data.story.photos ) {
            for(const p of params.data.story.photos) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'photos').then(res => {
                        p.filePath = res.data.secure_url
                        })
                    )
                }
            }

        }

        if(params.data.story && params.data.story.files ) {
            for(const p of params.data.story.files) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'attachments',params.data.registrationId+"-"+p.title||p.type||''+"-file").then(res => {
                            p.filePath = res.data.secure_url
                        })
                    )
                }
            }

        }

        if(params.data.files ) {
            console.log("dada",params.data)
            for(const p of params.data.files) {
                if(p.filePath && p.filePath.rawFile instanceof File) {
                    promises.push(uploadFile(p.filePath.rawFile, 'post_attachments',(moment(params.data.date).format("YYYY-MM-DDTHH-mm-ss"))+(params.data.recordId||'')+"-"+(p.title||params.data.title||'')+"-file").then(res => {
                            p.filePath = res.data.secure_url
                        })
                    )
                }
            }

        }

        if(params.data.families)
        {
            for(const f of params.data.families) {
                if(f.photo && f.photo.rawFile instanceof File){
                    promises.push(uploadFile(f.photo.rawFile, 'photos').then(res => {
                        f.photo = res.data.secure_url

                    }))
                }
            }
        }

        if ((resource === "Visit")) {


            if(params.data.expense && params.data.expense.rent && params.data.expense.rent.photo && params.data.expense.rent.photo.rawFile instanceof File) {
                promises.push(uploadFile( params.data.expense.rent.photo.rawFile, 'photos').then(res => {
                    params.data.expense.rent.photo = res.data.secure_url
                        // return dataProvider(fetchType, resource, {
                        //     ...rest_params,
                        //     data: {...data, photo: res.data.url},
                        // });
                    })
                )
            }
            if(params.data.livingCondition && params.data.livingCondition.photos ) {
                console.log("photos",params.data.livingCondition.photos)

                // for(const p of params.data.livingCondition.photos) {
                //
                // }

                params.data.livingCondition.photos.map((p,i)=>{
                    if(p && p.rawFile instanceof File) {
                        promises.push(uploadFile(p.rawFile, 'photos').then(res => {
                                params.data.livingCondition.photos[i] = res.data.secure_url
                            })
                        )
                    } else {
                        params.data.livingCondition.photos[i] = ''
                    }
                })




            }
            if(params.data.visitPhotos)
            {
                for(const p of params.data.visitPhotos) {
                    if( p && p.filePath && p.filePath.rawFile instanceof File) {
                        promises.push(uploadFile(p.filePath.rawFile, 'photos').then(res => {
                                p.filePath = res.data.secure_url
                            })
                        )
                    }
                }
            }




        }



        return Promise.all(promises).then(()=>{
            return dataProvider(fetchType, resource, {
                ...rest_params,
                data: {...data},
            });
        })


    }




    return dataProvider(fetchType, resource, params);
};


