import {
    Create,
    FormDataConsumer,
    Datagrid,
    DateField,
    Edit,
    List,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    DateInput,
    ImageInput,
    BooleanInput,
    NumberInput,
    Toolbar,
    DeleteButton,
    ImageField,
    NumberField,
    ArrayField,
    Filter,
    AutocompleteInput,
    useQueryWithStore,
    useDataProvider,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    FunctionField, ShowButton, EditButton


} from 'react-admin';
import React, {Fragment} from "react";
import {CustomToolbar, FamilyForm, formatPic, PersonalForm, PlaceForm} from "../helpers/commonForms";
import {useForm} from 'react-final-form';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import familyRelationshipData from "../helpers/familyRelationshipData";
import RichTextInput from "ra-input-rich-text";
import record from "./record";
import VisitSummery from "../components/visitSummery";
import Box from "@material-ui/core/Box";
import AutoCompleteFreeInput from "../uiComponents/AutoCompleteFeeInput";
import ClassificationField from "../uiComponents/classificationField";

const VisitFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput label="Search by Registration ID" source="registrationId" alwaysOn />*/}

        <ReferenceInput filterToQuery={searchText => {}} emptyText="All Records" label="Search by Id" source="_id" reference="Visit" alwaysOn  perPage={1000} allowEmpty>
            <AutocompleteInput  optionText={(record)=> record.registrationId?record.registrationId:"All Records"} allowEmpty options={{ type: 'search' }}/>
        </ReferenceInput >

        <ReferenceInput filterToQuery={searchText => {}} emptyText="All Records" label="Search by Name" source="_id" reference="Visit" alwaysOn  perPage={1000} allowEmpty>
            <AutocompleteInput  optionText={(record)=> record.name?(record.name||'')+" "+(record.fatherName?record.fatherName:''):"All Records"} allowEmpty options={{ type: 'search' }}/>
        </ReferenceInput >

        <SelectInput alwaysOn emptyText="All Classes"   source="category" label='Class Category' choices={[
            {id: "A", name: 'A - Can Manage without Finado'},
            {id: "B", name: "B - Can Manage only with Finado's Help"},
            {id: "C", name: "C Can't Manage even with finado's Help"},
        ]}/>
        <BooleanInput label="Official" source="official" />
    </Filter>
)


const list = props => <List filters={<VisitFilter/>}  perPage={100}  {...props} >
    <Datagrid rowClick='show'>
        {/*<TextField source="id" />*/}
        <TextField source="registrationId"  />
        {/*<TextField source="fullName"/>*/}
        {/*<FunctionField label="Outstanding" render={record => <MultiCurrency value={record.disburesedAmount-record.repayedAmount}/>} />*/}

        <FunctionField label="Full Name"  render={record =><>{record.name} {record.fatherName}</>} />


        <DateField label="Visited On" source="date"/>

        <ClassificationField label="Classification" source='category' />
        {/*<TextField label="Classification" source='category' />*/}

        <ShowButton/>
        <EditButton/>

    </Datagrid>
</List>

const VisitFromTab = props => {

    // const { loaded, error, data } = useQueryWithStore({
    //     type: 'getOne',
    //     resource: 'users',
    //     payload: { _ids: id }
    // });
    const form = useForm()
    const dataProvider = useDataProvider();
   const onSelected = (id) => {
      // console.log(ev)
       dataProvider.getOne('Record', { id})
           .then(({ data }) => {



             //  console.log(data)
               form.change('name',data.name)
               form.change('registrationId',data.registrationId)
               form.change('fatherName',data.fatherName)
               form.change('gFatherName',data.gFatherName)
               form.change('gender',data.gender)
               form.change('birthDate',data.birthDate)
               form.change('placeOfBirth',data.placeOfBirth)
               form.change('health',data.health)
               form.change('currentEducation',data.currentEducation)
               form.change('photo',data.photo)
               form.change('address',data.address)
               form.change('families',data.families)
               form.change('story',data.story)
               form.change('place',data.place)

                   form.change('expense.schoolFees',[data.currentEducation&&{
                       nameOfChild: data.name,
                       school:data.currentEducation
                   },...data.families.flatMap(f=>{
                       if(f.currentEducation&&f.currentEducation.status==="On Going"){
                           return [{
                               nameOfChild: f.name,
                               school:f.currentEducation
                           }]
                       }
                       return []
                   })])
               form.change('income.familyIncomes',[...data.families.flatMap(f=>{
                   if(f.isIncomeProvider){
                       return [{
                           providerName:f.name,
                           relationship:f.relationship,
                           description:f.proffession,
                           income:f.monthlyIncome,

                         }]
                   }
                   return []
               })])

           })
   }

    return <FormTab {...props} label='Visit'>
        <ArrayInput  source='takenBy'>
            <SimpleFormIterator>
                <TextInput label="Staff Name" source='name'/>
            </SimpleFormIterator>
        </ArrayInput>
        <DateInput label='Visit Date' source='date' />
        <ReferenceInput filterToQuery={searchText => {}}  onChange={ev=>onSelected(ev)}  label="Finado Child" source="record" reference="Record" perPage={1000}>
            <AutocompleteInput    optionText={choice=>`${choice.name} ${choice.fatherName} - ${choice.registrationId}`} />
        </ReferenceInput>
        <TextInput source='registrationId' />
    </FormTab>
}

const VisitForm = props => <TabbedForm toolbar={<CustomToolbar tabs={8}   {...props} />} {...props} onChange={e=>console.log(e)} initialValues={{takenBy:[{name:''}]}}>

    <VisitFromTab/>

    <FormDataConsumer>
        {formDataProps => (
            <PersonalForm {...formDataProps}/>
        )}
    </FormDataConsumer>
    <FormTab   label="Family">
        <ArrayInput label='Family Member'  source="families">
            <SimpleFormIterator  >


                <FormDataConsumer>
                    {formDataProps => (
                        <FamilyForm {...formDataProps}  />
                    )}
                </FormDataConsumer>


                {/*<Box display="flex" style={{width:"100%"}}>*/}
                {/*    */}
                {/*<TextInput label="Health Condition"  source="health.generalCondition" />*/}
                {/*<TextInput label="Health Remark"  source="health.remark" />*/}
                {/*</Box>*/}

            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>
    <FormDataConsumer>
        {formDataProps => (
            <PlaceForm {...formDataProps}/>
        )}
    </FormDataConsumer>
    <FormTab label='Expenses'>
        <Typography  variant="h6" gutterBottom>School Expenses</Typography>
        <ArrayInput label='School Fees'  source="expense.schoolFees">
            <SimpleFormIterator  >
                <TextInput label='Name of Child' source='nameOfChild' />

                <SelectInput label="Level"  source="school.level" choices={[
                    {id: "Elementary", name: 'Elementary'},
                    {id: "High School", name: 'High School'},
                    {id: "Higher Education", name: 'Higher Education'},
                ]}/>
                <SelectInput label="School Type"   source="school.schoolType" choices={[
                    {id: "Government", name: 'Government'},
                    {id: "Private", name: 'Private'},
                ]}/>
                <NumberInput step={0}  label='Fee' source='fee' />
            </SimpleFormIterator>
        </ArrayInput>

        <Typography  variant="h6" gutterBottom>Rent Expense</Typography>
        <Grid container style={{width:"100%"}}>
            <Grid item>
                <SelectInput label="House Type"  source="expense.rent.houseType" choices={[
                    {id: "Very Small", name: 'Very Small'},
                    {id: "Small", name: 'Small'},
                    {id: "Medium", name: 'Medium'},
                    {id: "Large", name: 'Large'},
                ]}/>
            </Grid>
            <Grid item>
                <NumberInput step={0}  label='Fee' source='expense.rent.fee' />
            </Grid>
        </Grid>

        <ImageInput format={ formatPic } source="expense.rent.photo" label="House Picture" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="src" title="title" />
        </ImageInput>
        <Typography  variant="h6" gutterBottom>Utility Expenses</Typography>
        <ArrayInput  label='Utility Expenses'  source="expense.utilityExpenses">
            <SimpleFormIterator  >

                {/*<SelectInput  label="Utility"  source='type' choices={[*/}
                {/*    {id: "Water", name: 'Water'},*/}
                {/*    {id: "Electricity", name: 'Electricity'},*/}
                {/*    {id: "Gas", name: 'Gas'},*/}
                {/*    {id: "Wood", name: 'Wood'},*/}
                {/*    {id: "Other", name: 'Other'},*/}
                {/*]}/>*/}

                <AutoCompleteFreeInput  label="Utility"  source='name' choices={[
                    {id: "Water", name: 'Water'},
                    {id: "Electricity", name: 'Electricity'},
                    {id: "Gas", name: 'Gas'},
                    {id: "Wood", name: 'Wood'},
                        ]}/>
                {/*<TextInput label='name' source='name' />*/}
                <TextInput label='Remark' source='remark' />
                <NumberInput step={0}  label='Last Month Fee' source='lastFee' />
                <NumberInput  step={0}  label='Average Fee' source='fee' />
            </SimpleFormIterator>
        </ArrayInput>

        <Typography  variant="h6" gutterBottom>Medical Expenses</Typography>
        <ArrayInput label='Medical Expenses'   source="expense.medicalExpenses">
            <SimpleFormIterator  >
                <SelectInput  label="Relationship" source='relationship' choices={familyRelationshipData} />
                <TextInput label='Medical Condition/Description' source='description' />
                <BooleanInput label='Unable to Work' source='unableToWork' />
                <NumberInput step={0}  label='Fee' source='fee' />
                <TextInput label='Remark' source='remark'/>
            </SimpleFormIterator>
        </ArrayInput>

        <Typography  variant="h6" gutterBottom>Basic Food Expenses</Typography>
        <ArrayInput label='Food Expenses'   source="expense.foodExpenses">
            <SimpleFormIterator  >

                <FormDataConsumer>
                    {formDataProps => <FoodItemsForm {...formDataProps}/>}
                </FormDataConsumer>/


            </SimpleFormIterator>
        </ArrayInput>

        <Typography  variant="h6" gutterBottom>Other  Expenses</Typography>
        <ArrayInput label='Other Expenses'   source="expense.otherExpenses">
            <SimpleFormIterator  >
                <TextInput label='description' source='description' />
                <NumberInput step={0}  label='fee' source='fee' />
                <BooleanInput label='Is Necessary' source='necessary '/>
            </SimpleFormIterator>
        </ArrayInput>


    </FormTab>
    <FormTab label='Income'>
        <Typography  variant="h6" gutterBottom>Income From Family Members</Typography>
        <ArrayInput label='Family Income'   source="income.familyIncomes">
            <SimpleFormIterator  >
                <TextInput label='Provider Name' source='providerName' />
                <SelectInput  label="Relationship" source='relationship' choices={familyRelationshipData} />
                <TextInput label='Work Description' source='description' />
                <TextInput label='Work Place' source='workPlace' />
                <NumberInput label='Income' source='income' />

            </SimpleFormIterator>
        </ArrayInput>
        <Typography  variant="h6" gutterBottom>Other  Income Means</Typography>
        <ArrayInput label='Other Incomes'   source="income.otherIncomes">
            <SimpleFormIterator  >
                <TextInput label='Description' source='description' />

                <NumberInput step={0}  label='Income' source='income' />
                <TextInput label='Remark' source='remark' />
            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>
    <FormTab label='Assets / Living Conditions'>
        <CheckboxGroupInput label='Common Assets' source="asset.commonAssets" choices={[
            { id: 'OtherFurniture', name: 'Other Furniture' },
            { id: 'Sofa', name: 'Sofa' },
            { id: 'Tv', name: 'TV' },
            { id: 'Refrigerator', name: 'Refrigerator' },
        ]} />
        <ArrayInput label='Other Notable Assets'   source="asset.otherAssets">
            <SimpleFormIterator  >
                <TextInput label='Description' source='description' />
                <BooleanInput label='Expensive' source='isExpensive' />
                <BooleanInput label='Necessary' source='isNecessary' />

            </SimpleFormIterator>
        </ArrayInput>

        <CheckboxGroupInput label='Utilities' source="livingCondition.utilities" choices={[
            { id: 'PrivateSanitary', name: 'Private Sanitary' },
            { id: 'PublicSanitary', name: 'Public Sanitary' },
            { id: 'PublicWater', name: 'Public Water' },
            { id: 'TapWater', name: 'Tap Water' },
            { id: 'Electric', name: 'Electric City' },
        ]} />

        <RichTextInput label='Remark About living condition' source='livingCondition.remark' />
        <ImageInput multiple={true} format={ formatPic } source="livingCondition.photos" label="Pictures" accept="image/*" placeholder={<p>Drop your file here</p>}>
            <ImageField source="src" title="title" />
        </ImageInput>
    </FormTab>
    <FormDataConsumer>
        {formDataProps => (
            <ResultForm {...formDataProps}/>
        )}
    </FormDataConsumer>


</TabbedForm>


const ResultForm = props => {
    const form = useForm()
    const {formData} = props;
    //console.log(props)
    //console.log(formData)
    const calculateTotalExpense = (expense) => {
        const schoolFees = expense.schoolFees && expense.schoolFees.reduce((sum,cv)=>cv && sum+cv.fee,0)
        const otherExpenses = expense.otherExpenses && expense.otherExpenses.reduce((sum,cv)=>cv && sum+cv.fee,0)
     //   return schoolFees + otherExpenses
        const foodExpenses = expense.foodExpenses &&  expense.foodExpenses.reduce((sum,cv)=>cv && sum+cv.fee,0)

        const  utilityExpenses  = expense.utilityExpenses &&  expense.utilityExpenses.reduce((sum,cv)=>cv && sum+cv.fee,0)
        const medicalExpenses = expense.medicalExpenses &&  expense.medicalExpenses.reduce((sum,cv)=>cv && sum+cv.fee,0)
        const houseRent = expense.rent && expense.rent.fee
        return (schoolFees || 0) + (otherExpenses || 0) + (foodExpenses || 0) + (utilityExpenses ||0 ) + (medicalExpenses || 0) + (houseRent || 0)
       // return schoolFees + medicalExpenses + utilityExpenses + medicalExpenses + houseRent;
    }
const calculateTotalIncome = (income) =>{
   const familyIncome = income.familyIncomes && income.familyIncomes.reduce((sum,cv)=>cv && sum+cv.income,0)
  const otherIncome = income.otherIncomes && income.otherIncomes.reduce((sum,cv)=>cv && sum+cv.income,0)
 return (familyIncome || 0) + (otherIncome || 0)
}
if(!(formData.result && formData.result.expenseEdited)) {

    form.change('result.estimatedExpense', formData.expense && calculateTotalExpense(formData.expense))


}
    if(!(formData.result && formData.result.incomeEdited)) {
      form.change('result.estimatedIncome', formData.income && calculateTotalIncome(formData.income) )
        // form.change('result.trueIncome', formData.income && calculateTotalIncome(formData.income) )

    }


    return   <FormTab {...props} label='Result'>
        <Grid container spacing={2} style={{width:"100%"}}>
            <BooleanInput label='Edit' source='result.expenseEdited'/>
            <NumberInput disabled={!(formData.result && formData.result.expenseEdited)}  label='Total Estimated Expense' source='result.estimatedExpense' />
            <NumberInput onChange={e=>{
                form.change('result.remainingIncome', formData.result && ((formData.result.totalIncome || 0) - (+e.target.value || 0)))
            }} label='Total Necessary Expence' source='result.necessaryExpense' />
            <TextInput label='Expense Remark' source='result.expenseRemark' />
        </Grid>
        <Grid container style={{width:"100%"}}>
            <BooleanInput label='Edit' source='result.incomeEdited'/>
            <NumberInput disabled={!(formData.result && formData.result.incomeEdited)} label='Total Income' source='result.estimatedIncome' />
            <NumberInput onChange={e=>{
                form.change('result.totalIncome', formData.result && (+e.target.value+ (formData.result.finadoFund||0)))
        //        form.change('result.trueIncome', formData.result && (+e.target.value+ (formData.result.finadoFund||0)))
                form.change('result.remainingIncome', formData.result && (+e.target.value+ (formData.result.finadoFund||0) - (formData.result.necessaryExpense || 0)))

            }} label='True Income' source='result.trueIncome' />
            <TextInput label='Income Remark' source='result.incomeRemark' />
        </Grid>


            <Grid  container direction='column' style={{width:"50%"}}  >
                <Grid item>
                    <NumberInput onChange={e=>{
                        form.change('result.totalIncome', formData.result && (+e.target.value+ (formData.result.trueIncome||0)))
                        // form.change('result.remainingIncome', formData.result && (+e.target.value+ (formData.result.finadoFund||0) - (formData.result.necessaryExpense || 0)))

                        form.change('result.remainingIncome', formData.result && (+e.target.value+ (formData.result.trueIncome||0) - (formData.result.necessaryExpense || 0)))
                    }} fullWidth  label='Current Finado Fund (For all sponsored)' source='result.finadoFund' />

                </Grid>
                <Grid item>
                    <NumberInput disabled fullWidth label='Total Income (True Income + Finado Fund)' source='result.totalIncome' />
                </Grid>
                <Grid item>
                    <NumberInput disabled  label='Remaining' source='result.remainingIncome' />
                    <TextInput label='Remaining Remark' source='result.remainingRemark' />
                </Grid>
            </Grid>
            <Grid  container direction='column' style={{width:"50%"}} >
                <Grid item>
                    <Typography  variant="h6" gutterBottom>Conclusion Based on the above results</Typography>
                    <RadioButtonGroupInput source="category" choices={[
                        { id: 'A', name: 'Can Mange without Finado' },
                        { id: 'B', name: ' Can manage only with Finado’s fund' },
                        { id: 'C', name: 'can’t manage even with Finado’s fund' },
                    ]} />
                </Grid>

            </Grid>

        <Typography  variant="h6" gutterBottom>Motivation of the ranking (apart from the above results )</Typography>
        <RichTextInput source='motivation' label='Motivation' />

        <ArrayInput label='Photos' source="visitPhotos">

            <SimpleFormIterator  >
                {/*<TextInput source="filePath" />*/}
                <ImageInput format={ formatPic } source="filePath" label="Photo" accept="image/*" placeholder={<p>Drop your file here</p>}>
                    <ImageField source="src" title="title" />
                </ImageInput>

                    <TextInput label='Title' source="title" />
                    {/*<DateInput label='Date' source="date"  options={{ format: 'DD/MM/YYYY' }}/>*/}
                    <TextInput multiline label='Description' source="description" />



            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>


}
const create = ({permissions,...props}) => <Create {...props}>
    <VisitForm/>
</Create>

const edit = props => <Edit {...props} >
  <VisitForm/>
</Edit>

const show = props => <Show {...props} >
    <TabbedShowLayout>
        <Tab  label="Summery">
            <VisitSummery/>
        </Tab>
    </TabbedShowLayout>
</Show>

const FoodItemsForm = ({getSource,scopedFormData, ...rest }) => {
    const form = useForm();
    return <Fragment>
        <AutoCompleteFreeInput label='Food Item' source={getSource("name")} choices={[
            {id: "Onion", name: 'Onion'},
            {id: "Teff", name: 'Teff'},
            {id: "Oil", name: 'Oil'},
            {id: "Shiro", name: 'Shiro'},
            {id: "Berbere", name: 'Berbere'},
        ]}/>

        <NumberInput step={0}  label='Usage' source={getSource("usage")} onChange={ev=> form.change(getSource("fee"), ev.target.value*((scopedFormData && scopedFormData.unitPrice)||0))} />
        <AutoCompleteFreeInput label='Unit' source={getSource("unit")} choices={[
            {id: "Kgs", name: 'Kgs'},
            {id: "Lts", name: 'Litters'},
        ]}/>
        <NumberInput step={0}  label='Price Per Unit ' source={getSource("unitPrice")} onChange={ev=> form.change(getSource("fee"), ev.target.value*((scopedFormData && scopedFormData.usage)||0))} />
        <NumberInput  step={0}  label='fee' source={getSource("fee")} />
    </Fragment>
}


export  default {create, list, show, edit}
