import React from 'react';
import {Button} from 'react-admin'
import RadioGroup from '@material-ui/core/RadioGroup';
import DiscontinueDialog from "./discontinueSponsershipDialog";
import Card from "@material-ui/core/Card";
import moment from "moment";
import LetterIcon from "@material-ui/icons/ReceiptOutlined";
import Link from "@material-ui/core/Link";

// import DateField from '@material-ui/core/';


const RecordActions = ({ source, record = {}  }) => {


    return (
        <Card style={{marginTop:50}}>
            {/*Status*/}
            {/*<RadioGroup*/}
            {/*    className="select-all"*/}
            {/*    color="primary"*/}

            {/*/>*/}
            {record.status!=="Discontinued"&&<DiscontinueDialog record={record}/>}
            {record.status==="Discontinued"&&record.exitInfo&&<div>
                Discontinued on <strong>{moment(record.exitInfo.date).format('MMMM DD, YYYY')} </strong> <br/>
                Reason <strong>{record.exitInfo.reason}</strong><br/>
                {record.exitInfo.remark} <br/>
                <LetterIcon/>Leave Letter <Link target="_blank" rel="noreferrer" href={record.exitInfo.leaveLetter}>{record.exitInfo.leaveLetter.split(/[\\\/]/).pop()}</Link>


            </div>}


        </Card>
    );
};

export default RecordActions;
