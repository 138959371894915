import React, {useRef} from 'react';
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import renderHTML from 'react-render-html';
import CardHeader from "@material-ui/core/CardHeader";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import ImagesWithLightBox from "../uiComponents/ImagesWithLightBox";
import AvatarWithLightBox from "../uiComponents/AvatarWithLightBox";
const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));


const RecordSummery = ({ source, record = {}  }) => {
    const classes = useStyles();
    const componentRef = useRef();
    if (!record || !record.families) {
        return null;
    }
    // console.log(record)
    // const value = get(record, source);
    // if (value == null) {
    //     return null;
    // }
    return <div>
        <ReactToPrint
            trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
            content={() => componentRef.current}
            pageStyle={""}
        />

<div ref={componentRef}>
        {/*<CardMedia*/}
        {/*    className={classes.media}*/}
        {/*    image={record.photo}*/}
        {/*    title={record.name}*/}
        {/*/>*/}
        <Grid container spacing={5}>
            <Grid item>
                <Card>
                    <CardContent>
                        <h2> {record.name} {record.fatherName} {record.gFatherName} </h2>
                        {record.gender}, Age: <strong> {moment().get('year')-moment(record.birthDate).get('year')}</strong> <br/>
                       {record.story && record.story.story && renderHTML(record.story.story)} <br/>
                        {record.address && record.address.email && <>
                          Email: {record.address.email} <br/>
                        </>}
                        {record.address && record.address.phones && record.address.phones[0] && <>
                           Phone: {record.address.phones[0].number} <br/>
                        </>}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item >
                <AvatarWithLightBox title={record.name} picture={record.photo} className={classes.large} />
        </Grid>
            {record.registrationId &&<Grid item>
                <h2>{record.registrationId }</h2>
            </Grid>}
        </Grid>


        <strong>Family</strong>
        <Grid container spacing={5}>
            {record.families.map(f=>
                <Grid item>
                    <Card>
                        <CardHeader
                            avatar={
                                <AvatarWithLightBox title={record.name} picture={f.photo} />
                                // <Avatar alt={record.name} src={f.photo}  />
                            }
                            title={f.relationship}
                            subheader={<> {f.name}   {f.fatherName} </>}
                        >


                        </CardHeader>

                  <CardContent>

                    Age {moment().get('year')-moment(f.birthDate).get('year')},
                      {f.health.generalCondition &&  <>in {f.health.generalCondition} Health Condition</>} <br/>
                    Work: {f.profession},
                      {f.monthlyIncome &&<>Monthly Income: {f.monthlyIncome} </>}<br/>
                    {f.address&&<>
                       <strong>Address</strong>    <br/>
                        Phone: {f.address.phones[0]&&f.address.phones[0].number} <br/>
                        {f.address.email && <>Email: {f.address.email}</>}

                    </>}
                  </CardContent>
                    </Card>


                </Grid>
            )}
        </Grid>

        {record.place&&<>
            <strong> Living Place</strong> <br/>
            {record.place.type}  {record.place.type === 'Rental'?<>Cost {record.place.monthlyCost}</>:''} <br/>
            Address <br/>
            {record.place.address&&<>
                {record.place.address.locationText} {record.place.address.subcity} Woreda {record.place.address.woreda} {record.place.address.city}
                <br/>
            </>}
        </>}

    {/*{record.story && record.story.photos && <Card>*/}
    {/*    <CardHeader title='Photos'/>*/}
    {/*    <CardContent>*/}
    {/*        <Grid container spacing={2}>*/}
    {/*            {record.story.photos.map(p => <Grid md={6} item>*/}
    {/*                <Card>*/}
    {/*                    <CardMedia*/}
    {/*                        className={classes.media}*/}
    {/*                        image={p.filePath}*/}
    {/*                        title={p.title}*/}
    {/*                    />*/}
    {/*                    <CardContent>*/}
    {/*                        {p.title}*/}
    {/*                        {p.description}*/}
    {/*                    </CardContent>*/}

    {/*                </Card>*/}
    {/*            </Grid>)}*/}
    {/*        </Grid>*/}
    {/*    </CardContent>*/}
    {/*</Card>}*/}

    <Card>
        <CardContent>
            {record.story && <ImagesWithLightBox photos={record.story.photos} />}
        </CardContent>
    </Card>

    </div>
    </div>
}

export default RecordSummery;
