import React from 'react';
import {AppBar} from 'react-admin';
// import MyUserMenu from "./userMenu";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
// import CurrencyMenu from "./currencyMenu";
// import ArrowBack from '@material-ui/icons/ArrowBack';
// import HelpMenu from "./helpMenu";
// import BackButton from "./backButton";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    box: {
        height:"15px", width:"20%"
    },
    smallBox: {
        height:"15px", width:"10%"
    }
});
// const MyMenu = (props) => <Menu {...props} />
const MyAppBar = props => {
    const classes = useStyles();


    return (
        <><AppBar  {...props}>

        </AppBar>
            <Box display="flex" >

                <Box  display="flex" style={{backgroundColor:"#74bb00"}} className={classes.box}>

                </Box>
                <Box  display="flex" style={{backgroundColor:"#000"}} className={classes.smallBox}>

                </Box>

               <Box  display="flex"  style={{backgroundColor:"#ffae0d"}} className={classes.box}>

               </Box>

                <Box  display="flex" style={{backgroundColor:"#d94c00"}} className={classes.box}>

                </Box>

                <Box  display="flex" style={{backgroundColor:"#000"}} className={classes.smallBox}>

                </Box>

                <Box  display="flex" style={{backgroundColor:"#fff"}} className={classes.smallBox}>

                </Box>
                <Box  display="flex" style={{backgroundColor:"#0d7aff"}} className={classes.smallBox}>

                </Box>


            </Box>
            </>
    );
};

export default MyAppBar;
