import {ApolloClient,ApolloLink, InMemoryCache, HttpLink} from 'apollo-boost';
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPH_API });
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    console.log(operation)
    operation.setContext({
        headers: {
            authorization: `bearer ${operation.operationName==="IntrospectionQuery"?'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YzlhZTkyMGQzNDU3NDg1Y2E3MTM2NCIsInJvbGVzIjpbImFkbWluIl0sImlhdCI6MTY5MDkzOTAyNn0.qG9J8ttic6UgXdkAIO88cqzHaJhENqTmPCpUd53TFR0':localStorage.getItem('token')}`,
        }
    });

    return forward(operation);
})


export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authMiddleware.concat(httpLink),
});



//
// export const client = new ApolloClient({
//     uri: process.env.REACT_APP_GRAPH_API
// });

