import React, {useState} from 'react';
import {DateInput, FormTab, TextInput,useInput} from "react-admin";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Zemen from "zemen";
import { useFormState } from 'react-final-form';
const EthiopianDateInput = props => {

    //const {onChange}
    const {
        input:{ name, onChange,value, ...rest },
        meta: { touched, error }
    } = useInput(props);

   // console.log(value)
    const [ecDate,setEcDate] = useState('');
    React.useEffect(() => {
       if(value)setEcDate(Zemen.toEC(value).toString())
    }, [value]);

    function convertToGC(e) {
        if(e.target.value) {
            const gcDate = Zemen.toGC(e.target.value)
            onChange(gcDate.toDateString());
        }
    }

    function convertToEC(e) {
     //   console.log(e)
     setEcDate(Zemen.toEC(e.target.value).toString())
        onChange(e.target.value);
    }
    return (
        <Grid container spacing={2}>
            <Grid item >
                <DateInput  label={props.label+" GC"} source={name} onChange={convertToEC}  options={{ format: 'DD/MM/YY' }} style={{ display: 'inline', width:"100%", float: 'left'}} />

            </Grid>
            <Grid item >
            <TextField placeholder="YYYY-MM-DD" margin="dense" label={props.label+" Ethiopian C"} value={ecDate} onChange={e=>setEcDate(e.target.value)}  onBlur={convertToGC}  />
            </Grid>
        </Grid>
    );
};

export default EthiopianDateInput;
