import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";



const ClassDisplay = ({classi}) => {
    return (
        <Grid spacing={3} container>
            <Grid  item>
                Class
            </Grid>
            <Grid  item>
                <Typography  variant="h2">    {classi}</Typography>

            </Grid>

        </Grid>
    );
};




const VisitResultClass = ({category,height=100}) => {
    return (
        <div>
            {category === 'A'&&
                <Card style={{backgroundColor:'#8db61b',color:'#FFF'}}>
                    <CardContent>
                       <ClassDisplay classi={category}/>
                        <h5>Can Manage without finado fund</h5>
                    </CardContent>
                </Card>
            }
            {category === 'B'&& <Card style={{backgroundColor:'#d5a821',color:'#FFF'}}>
                <CardContent>
                    <ClassDisplay classi={category}/>
                    <h5>Can Manage only with finado fund</h5>
                </CardContent>
            </Card>}
            {category === 'C'&& <Card style={{backgroundColor:'#b64c1b',color:'#FFF'}}>
                <CardContent>
                    <ClassDisplay classi={category}/>
                    <h5>Can't Manage even with finado fund</h5>
                </CardContent>
            </Card>}
        </div>
    );
};

export default VisitResultClass;
