import React from 'react';
import PictureWithLightBox from "./PictureWithLightBox";
import get from "lodash/get";
import {isImage} from "../helpers/javascriptHelpers";
const LightBoxField = ({label, source, record = {}  }) => {
    if (!record) {
        return null;
    }
    let value = get(record, source);
    // console.log({value})
    if(value&&value.length) {
        value = value[0].filePath || value
    }
    // console.log({value})

    return (
        ( value && !Array.isArray(value) && isImage(value))?  <PictureWithLightBox title={label} picture={value}/>:<></>
    );
};

export default LightBoxField;
