import React from 'react';
import Card from "@material-ui/core/Card";
import VisitResultClass from "./visitResultClass";
import {get} from 'lodash'
const ClassificationField = ({ source, record = {} ,onDelete }) => {
    if (!record) {
        return null;
    }
    const value = get(record, source);
    if (value == null) {
        return null;
    }
    return (
        <Card style={{}}>
            <VisitResultClass category={value} />
        </Card>
    );
};

export default ClassificationField;
