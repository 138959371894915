import React, {useState} from 'react';
import {Button, FormTab, useDataProvider,useRedirect} from "react-admin";
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from "@material-ui/core/FormGroup";
import CheckBox  from "@material-ui/core/Checkbox";
import FormControlLabel  from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {DatePicker} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useFileUpload } from 'use-file-upload'
import {uploadFile} from "../helpers/fileUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import LetterIcon from '@material-ui/icons/ReceiptOutlined'
import Link from "@material-ui/core/Link";
const DiscontinueSponsorship = ({record,showNotification}) => {
    const dataProvider = useDataProvider();
    const [reason,setReason] = useState('')
    const [uploading,setUploading] = useState(false)
    const [date,setDate] = useState(new Date().toString())
    const [remark,setRemark] = useState('')
    const [processedBy,setProcessedBy] = useState('')
    const [leaveLetter,setLeaveLetter] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false);
    const [file, selectFile] = useFileUpload()
    const handleClickOpen = () => {
        //showNotification('Sorry, we couldn\'t upload the file, tye again later','error')
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };
    const redirect = useRedirect();
    const reasons = ['Home Visit Result', 'Sponsor Requested','Beneficiary Requested'];
    const onFileUpload = () => {

        selectFile({ accept: '*.*' }, ({ source, name, size, file }) => {
            // file - is the raw File Object
            //console.log({ source, name, size, file })
            // Todo: Upload to cloud.
            setUploading(true)
            uploadFile(file,'documents',record.registrationId+'-LeaveLetter' ).then(res=>{
                const data = res.data
                if(data) {
                    setLeaveLetter(data.secure_url)
                }
                setUploading(false)
              //  console.log(res)
            }).catch(e=>{
                showNotification('Sorry, we couldn\'t upload the file, tye again later','error')
            })
        })
    }
        const handleConfirm = () => {

        const exitInfo = {reason, remark, leaveLetter, date}
        dataProvider.update('Record',{data:{exitInfo, status:'Discontinued',id:record.id}}).then(()=>{
            setDialogOpen(false)
            redirect('/Record')
        })
    };
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} label="Discontinue Sponsorship" />
            <Dialog  fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose}  aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Discontinue {record.name}'s Sponsorship</DialogTitle>
                <DialogContent>
                    <div>
                        <FormGroup>
                            <DatePicker margin='normal' value={date} label="Date Discontinued" onChange={ev=>setDate(ev.toString())} />
                        <FormControl  margin='normal'>
                            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                onChange={ev=>setReason(ev.target.value)}
                            >
                                {reasons.map(r=>  <MenuItem value={r}>{r}</MenuItem>)}

                            </Select>
                        </FormControl>
                        <TextField
                            value={remark}
                            onChange={ev=>setRemark(ev.target.value)}
                            label="Remark"
                            multiline
                            rows={3}
                            rowsMax={5}
                            margin='normal'
                        />

                        </FormGroup>
                        </div>


                </DialogContent>
                <Grid container>
                    <Grid item>
                        <Button color="primary"
                                disabled={uploading}
                                onClick={onFileUpload}
                                label={"Upload the leave letter"}
                                startIcon={<LetterIcon/>}
                        >

                            {uploading&& <CircularProgress size={24} />}
                        </Button>
                    </Grid>
                    <Grid item>
                        {leaveLetter&&<div>
                            <LetterIcon/> <Link target="_blank" rel="noreferrer" href={leaveLetter}>{leaveLetter.split(/[\\\/]/).pop()}</Link>
                        </div>}
                    </Grid>
                </Grid>



                <DialogActions>
                    <Button disabled={uploading} onClick={handleConfirm} label="Confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(undefined, {showNotification})(DiscontinueSponsorship);
