import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple';
import gql from 'graphql-tag'
import fields from "./fields";
import _ from "lodash"
import {client} from "./apolloClient";
// import * as gqlTypes from 'graphql-ast-types-browser';
// import {buildFields} from "ra-data-graphql-simple/src/buildGqlQuery";
import buildVariables from "ra-data-graphql-simple/src/buildVariables";
import {uploadFile} from "./fileUpload";
import moment from "moment";
import {instanceOf, string} from "prop-types";
// import buildFieldList from 'ra-data-graphql';
// import { __schema as schema } from './schema';
function removeTypeName(o,thingsToRemove=[]) {

    if(!o || o.toString()===o){
        return o
    }

    Object.keys(o).forEach(key => {

        const param = o[key];
        //const x =


        //console.log('param',param)

        if(!(param instanceof Object))
        {
            return
        }
        if(param instanceof Object &&
            !Array.isArray(param))
        {
            // console.log(param)
            o[key] = _.omit(param,['id','tableData','__typename',...thingsToRemove])
            o[key]= removeTypeName(param,thingsToRemove)
        }

        if(Array.isArray(param))
        {
            //console.log('array',param)
            o[key] = param.map(p=>{
                p = removeTypeName(p,thingsToRemove)
                return p
            })
        }


        // return removeTypeName(param)
    })
    return  _.omit(o,['id','tableData','__typename','updatedAt','createdAt',...thingsToRemove])
}

const myBuildQuery = introspection =>(fetchType, resource, params) => {

    const queryName = introspection.resources.find(r => r.type.name === resource)[fetchType].name;
     // console.log(resource.type)

    const newParams = {...params,_id:params.id}
    //console.log(introspection)
    const builtQuery = buildQuery(introspection)(fetchType, resource, newParams);

    // const resourceR = introspection.resources.find(
    //     r => r.type.name === resource
    // );
   // const fieldX = buildFields(introspection)(resourceR.type.fields);
    // console.log( gqlTypes.selectionSet(fieldX))

   if(builtQuery.variables.sortField)
   {
       const field = builtQuery.variables.sortField==='id'?'_id':builtQuery.variables.sortField
       builtQuery.variables.sort = `${field.toUpperCase()}_${builtQuery.variables.sortOrder.toUpperCase()}`

   }

    if (fetchType === 'GET_LIST') {

        if(!fields[queryName]) {
            return builtQuery
        }


        let filter = builtQuery.variables.filter;

       // console.log(filter)
        if(filter.placeType) {
            filter.place = {type: filter.placeType}
            filter.placeType = undefined
        }
     //   console.log(filter)
     //
     //    if(queryName === "allRecords")
     //    {
     //        if(!filter){
     //            filter = {official:true, status:"Active"}
     //        }
     //        else  {
     //            filter.official =  filter.official || true
     //            filter.status = filter.status ||"Active"
     //        }
     //    }




        return {
            // Use the default query variables and parseResponse
            ...builtQuery,
            query: gql`query ${queryName}($filter: FilterFindMany${resource}Input, $sort: SortFindMany${resource}Input) {
 items:${queryName}(filter:$filter,sort:$sort) {
 id
 ${fields[queryName]}
}
  total: _${queryName}Meta(filter:$filter){
    count
  }
}`,
            variables: {...builtQuery.variables, filter}

        }
    }

    if (fetchType === 'GET_ONE') {
        const query = gql`query ${queryName}($id: MongoID!) {
                    ${queryName}(_id: $id) {
                          id
                          ${fields[queryName]}
                    }
                }`
       // console.log("myQ", query)
        if(!fields[queryName])
        {
            query.definitions[0].selectionSet.selections[0].selectionSet =  builtQuery.query.definitions[0].selectionSet.selections[0].selectionSet
        }
        // console.log("myQ", query)
        // console.log("builtQ",builtQuery.query)
      //  console.log("builtQ",builtQuery)
        // const fields = buildFields(introspectionResults)(resource.type.fields);
        return {
            // Use the default query variables and parseResponse
            ...builtQuery,
            query,
            parseResponse: response => ({data: response.data[queryName]}),

            // Override the query

        };
    }

    if (fetchType === 'DELETE') {
        return {
            ...builtQuery,
            query: gql`mutation ($id: MongoID!) {
  ${queryName} (_id: $id)
  {
    recordId
  }
}`,
     parseResponse: response => ({data: {id:response.data[`delete${resource}`].recordId}}),

        }
    }

    if(fetchType === 'CREATE') {
        // console.log(builtQuery.variables)
        // console.log(moment())
        const x =  removeTypeName(builtQuery.variables,['id','_id','age'])
        // if (params.data.photo && params.data.photo.rawFile instanceof File) {
        //     // uploadFile(params.data.photo.rawFile, 'photos')
        //     // x.photo = res.data.url;
        // }



            return {
                // Use the default query variables and parseResponse
                ...builtQuery,
                query: gql`mutation ${queryName}($record: CreateOne${resource}Input!) {
                    ${queryName}(record: $record) {
                      record {
                         id 
                         ${fields[queryName] || ''}
                      }
                    }
                }`,
                variables: {record:x},
                parseResponse: response => ({data: response.data[queryName].record}),

                // Override the query

            };




    }

    if(fetchType === 'UPDATE') {


       const x =  removeTypeName(builtQuery.variables,['age','fullName','id'])//_.omit(builtQuery.variables,['id','__typename'])  // omit from rest of the tree

        return {
            // Use the default query variables and parseResponse
            ...builtQuery,
            query: gql`mutation ${queryName}($record: UpdateById${resource}Input!) {
                    ${queryName}(record: $record) {
                      record {
                         id   
                         ${fields[queryName] || ''}
                      }
                    }
                }`,
            variables: {record:{...x,_id:builtQuery.variables.id}},
            parseResponse: response => ({data: response.data[queryName].record}),

            // Override the query

        };
    }

    if (fetchType === 'GET_MANY') {
        return {
            // Use the default query variables and parseResponse
            ...builtQuery,
            variables: {filter: {_ids: params.ids}}
        }
    }
    return builtQuery;
};

// export default  buildGraphQLProvider({ introspection: { schema }, client, buildQuery:  myBuildQuery })
export default  buildGraphQLProvider({ client, buildQuery:  myBuildQuery })
