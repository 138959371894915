import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useInput, required } from 'react-admin';

const AutoCompleteFreeInput = (props) => {
    const {
        input: { name, onChange, value },
        meta: { touched, error },
        isRequired
    } = useInput(props);
    return (
        <Autocomplete
            style={{width:250}}
            id="free-solo-demo"
            freeSolo
            value={value}
            onChange={(event, newValue) => {
               //console.log(event)
                onChange({...event,target:{value:newValue}})
            }}
            onInputChange={(event, newInputValue) => {
                onChange({...event,target:{value:newInputValue}})
            }}
            options={props.choices.map((option) => option.name)}
            renderInput={(params) => (
                <TextField
                    {...params}

                    name={name}
                           label={props.label}
                    required={isRequired}
                        //   onChange={onChange}
                           error={!!(touched && error)}
                           helperText={touched && error} margin="normal" variant="filled" />
            )}
        />
    );
};

export default AutoCompleteFreeInput
