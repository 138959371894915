import React, {useRef} from "react";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import renderHTML from "react-render-html";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import MaterialTable from "material-table";
import FieldDisplay from "../uiComponents/fieldDisplay";
import VisitResultClass from "../uiComponents/visitResultClass";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardMedia from "@material-ui/core/CardMedia";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print"
import ImagesWithLightBox from "../uiComponents/ImagesWithLightBox";
import AvatarWithLightBox from "../uiComponents/AvatarWithLightBox";
import PictureWithLightBox from "../uiComponents/PictureWithLightBox";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

const utilities =[
        { id: 'PrivateSanitary', name: 'Private Sanitary' },
{ id: 'PublicSanitary', name: 'Public Sanitary' },
{ id: 'PublicWater', name: 'Public Water' },
{ id: 'TapWater', name: 'Tap Water' },
{ id: 'Electric', name: 'Electric City' },
]

const assets = [
    { id: 'Furniture', name: 'Furniture' },
    { id: 'Tv', name: 'TV' },
    { id: 'Refrigerator', name: 'Refrigerator' },
]


const VisitSummery = ({ source, record = {}  }) => {
     const classes = useStyles();
    const componentRef = useRef();
    if (!record || !record.families) {
        return null;
    }
    return   <div>
        <ReactToPrint
            trigger={() => <Button variant="outlined"  color="primary"  startIcon={<PrintIcon/>}  >Print/PDF</Button>}
            content={() => componentRef.current}
            pageStyle={""}
        />
        <Button  startIcon={<CloudDownloadOutlined/>}  variant="outlined"  color="primary" href={`${process.env.REACT_APP_API}/reports/visit/${record.id}`}>Download Visit File</Button>;

    <div ref={componentRef} >
        Visit By : {record.takenBy.map(staff=><div>{staff.name}</div>)}
        Date Taken: {moment(record.date).format('MMMM, DD YYYY')}
        <Card>
            <CardHeader
             title='Beneficiary Detail'
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid md={8} item>
                        <Grid container spacing={3} >
                            <Grid item>
                                {/*<Avatar alt={record.name} src={record.photo}  className={classes.large}  />*/}
<AvatarWithLightBox title={record.name} picture={record.photo} className={classes.large} />
                            </Grid>
                            <Grid item>
                                <h2> {record.name} {record.fatherName} {record.gFatherName} </h2>
                                {record.gender}, Age: <strong> {moment().get('year')-moment(record.birthDate).get('year')}</strong> <br/>
                                BirthDate: {moment(record.birthDate).format('MMMM, DD YYYY')} <br/>
                               {record.story && record.story.story && renderHTML(record.story.story)} <br/>
                                {record.address && record.address.email && <>
                                    Email: record.address.email <br/>
                                </>}
                                {record.address && record.address.phones && <>
                                    Email: record.address.phones[0].number <br/>
                                </>}

                            </Grid>
                            <Grid item>
                                <h1>{record.registrationId}</h1>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid md={4} item>
                        <Typography  variant="h5" gutterBottom>Family Details</Typography>

                        {/*<MaterialTable options={{search: false, paging:false}}*/}
                        {/*               columns={[*/}
                        {/*                   { title: "Relationship", field: "relationship" },*/}
                        {/*                   { title: "Name", field: "name" },*/}

                        {/*               ]} data={record.families.filter(f=>f.relationship==='Mother'||f.relationship==='Father')} />*/}
                        <Typography  variant="h6" gutterBottom>Parents</Typography>
                        {record.families.filter(f=>f.relationship==='Mother'||f.relationship==='Father').map(f=>
                        <div>
                            {f.relationship}, {f.name} {f.fatherName}, Age: <strong> {moment().get('year')-moment(f.birthDate).get('year')}</strong>
                        </div>
                        )}

                        <Typography  variant="h6" gutterBottom>Siblings</Typography>
                        {record.families.filter(f=>f.relationship==='Brother'||f.relationship==='Sister').map(f=>
                            <div>
                                {f.relationship}, {f.name} {f.fatherName}, Age: <strong> {moment().get('year')-moment(f.birthDate).get('year')}</strong>
                            </div>
                        )}

                        <Typography  variant="h6" gutterBottom>Other Familly Members</Typography>
                        {record.families.filter(f=>f.relationship!=='Mother'&&f.relationship!=='Father'&&f.relationship!=='Brother'&&f.relationship!=='Sister').map(f=>
                            <div>
                                {f.relationship}, {f.name} {f.fatherName}, Age: <strong> {moment().get('year')-moment(f.birthDate).get('year')}</strong>
                            </div>
                        )}

                    </Grid>
                </Grid>


            </CardContent>
        </Card>


        <Card>
            <CardHeader title='Visit Assessment' />
            {record.result && <CardContent>
                <Grid container>
                    <Grid item md={9}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FieldDisplay label='Estimated Expense' value={record.result.estimatedExpense}/>

                            </Grid>
                            <Grid item>
                                <FieldDisplay label='Necessary Expense' value={record.result.necessaryExpense}/>
                            </Grid>
                            <Grid item>
                                <FieldDisplay label='Expense Remark' value={record.result.expenseRemark}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FieldDisplay label='Calculated Income (as they said)'
                                              value={record.result.estimatedIncome}/>
                            </Grid>

                            <Grid item>
                                <FieldDisplay label='True Income' value={record.result.trueIncome}/>
                            </Grid>
                            <Grid item>
                                <FieldDisplay label='Income Remark' value={record.result.incomeRemark}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FieldDisplay label='Current Fiando Fund (for the family)'
                                              value={record.result.finadoFund}/>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FieldDisplay label='Total Income' value={record.result.totalIncome}/>
                            </Grid>


                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FieldDisplay label='Remainig Income' value={record.result.remainingIncome}/>
                            </Grid>

                            <Grid item>
                                <FieldDisplay label='Remark' value={record.result.remainingRemark}/>
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <VisitResultClass category={record.category}/>
                    </Grid>
                </Grid>
                <Typography variant="h5" gutterBottom>Motivation of the ranking (apart from the above results
                    )</Typography>
                {record.motivation && renderHTML(record.motivation)}

            </CardContent>}
        </Card>
        <Card>
            <CardHeader title='Living Place Info' />
            <CardContent>
                <Grid container>
                    <Grid md={6} item>
                        Living Place: {record.place && record.place.address && <>{record.place.address.locationText}, {record.place.address.subcity} subcity, woreda {record.place.address.woreda}, H.No {record.place.address.houseNumber || "-"}</>}
                        <br/>
                        Place Type: <h4>{record.place && record.place.type}</h4>
                        <h4>Living Conditions</h4>
                        <div>
                            {utilities.map(u=>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={record.livingCondition.utilities.includes(u.id)}  />}
                                    label={u.name}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid md={4} item>
                        {record.place && record.place.photo&&<PictureWithLightBox picture={record.place.photo} /> }

                    </Grid>
                </Grid>


                <div>
                    {/*{record.livingCondition.photos.map(p=>{*/}
                    {/*    if(p!=''){*/}
                    {/*        return <img width={400} height={300} src={p}/>*/}
                    {/*    }*/}
                    {/*})}*/}
                    {record.livingCondition.photos && <ImagesWithLightBox photos={record.livingCondition.photos.map(i => ({filePath: i}))}/>
                    }
                </div>
                {/*{record.livingCondition && <>*/}
                {/*    {record.livingCondition.utilities.map(u=><div>{u}</div>)}*/}
                {/*</>}*/}


            </CardContent>
        </Card>
        <Typography  variant="h5" gutterBottom>Visit Details</Typography>

        <Card>
            <CardHeader title='Assets' />
            <CardContent>
     <Grid container spacing={2} >
         <Grid md={6} item>
             <div>
                 {assets.map(u=>
                     <FormControlLabel
                         control={<Checkbox disabled checked={record.asset.commonAssets.includes(u.id)}  />}
                         label={u.name}
                     />
                 )}
             </div>
         </Grid>

         <Grid md={6} item>
             <MaterialTable
                 options={{search: false, paging:false}}
                 columns={[
                     { title: "Type", field: "type" },
                     { title: "Description", field: "description" },
                     { title: "Is Necessary", field: "isNecessary" },
                     { title: "Is Expensive", field: "isExpensive" },
            ]}
                 data={record.asset.otherAssets}
                 title='Other Notable Assets'
             />
         </Grid>
     </Grid>

                {/*{record.livingCondition && <>*/}
                {/*    {record.livingCondition.utilities.map(u=><div>{u}</div>)}*/}
                {/*</>}*/}


            </CardContent>
        </Card>

        <Card>
            <CardHeader title='Expenses' />
            {record.expense && <CardContent>
                <Grid container spacing={2}>
                    <Grid md={6} item>
                        <MaterialTable
                            options={{search: false, paging: false}}
                            columns={[
                                {title: "Name of Benefitiary", field: "nameOfChild"},
                                {title: "School", field: "school.schoolName"},
                                {title: "Fee", field: "fee", type: "numeric"},

                            ]}
                            data={record.expense.schoolFees}
                            title='School Fees'
                        />
                    </Grid>

                    <Grid md={6} item>
                        <MaterialTable
                            options={{search: false, paging: false}}
                            columns={[
                                {title: "Name", field: "name"},
                                {title: "Usage", field: "usage", type: "numeric"},
                                {title: "Fee", field: "fee", type: "numeric"},

                            ]}
                            data={record.expense.utilityExpenses}
                            title='Utility Expenses'
                        />

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={6} item>
                        <MaterialTable
                            options={{search: false, paging: false}}
                            columns={[
                                {title: "Name", field: "name"},
                                {title: "Usage", field: "usage", type: "numeric"},
                                {title: "Unit Price", field: "unitPrice", type: "numeric"},
                                {title: "Fee", field: "fee", type: "numeric"},

                            ]}
                            data={record.expense.foodExpenses}
                            title='Food Expenses'
                        />
                    </Grid>

                    <Grid md={6} item>
                        <Card>
                            <CardHeader title="Rent Expense"/>
                            {record.expense.rent&&<CardMedia
                                image={record.expense.rent.photo}
                                title="xxx"
                            />}
                            {record.expense.rent ? <CardContent>
                                House Type: {record.expense.rent.houseType} <br/>
                                Fee: {record.expense.rent.fee} <br/>
                                Remark: {record.expense.rent.remark} <br/>
                                <img width={400} height={300} src={record.expense.rent.photo}/>  <br/>
                            </CardContent> : <CardContent>
                                No Rent Expense
                            </CardContent>}
                        </Card>

                    </Grid>
                </Grid>
                <MaterialTable
                    options={{search: false, paging: false}}
                    columns={[
                        {title: "Name", field: "name"},
                        {title: "Relationship", field: "relationship"},
                        {title: "Relationship", field: "relationship"},
                        {title: "Age", field: "age", type: "numeric"},
                        {title: "Fee", field: "fee", type: "numeric"},
                        {title: "Remark", field: "remark"},

                    ]}
                    data={record.expense.medicalExpenses}
                    title='Medical Expenses'
                />

                <footer/>

                <Grid container spacing={2}>

                    <Grid md={10} item>
                        <MaterialTable
                            options={{search: false, paging: false}}
                            columns={[
                                {title: "Desription", field: "description"},
                                {title: "Necessary", field: "necessary"},
                                {title: "Fee", field: "fee", type: "numeric"},

                            ]}
                            data={record.expense.otherExpenses}
                            title='Other Expenses'
                        />

                    </Grid>
                </Grid>


            </CardContent>}
        </Card>

        <Card >
            <CardHeader title="Income" />
            {record.income && <CardContent>

                <MaterialTable
                    options={{search: false, paging: false}}
                    columns={[
                        {title: "Provider Name", field: "providerName"},
                        {title: "Relationship", field: "relationship"},
                        {title: "Work", field: "description"},
                        {title: "WorkPlace", field: "Work Place"},
                        {title: "Income", field: "income", type: "numeric"},

                    ]}
                    data={record.income.familyIncomes}
                    title='Income from Family Members'
                />

                <MaterialTable
                    options={{search: false, paging: false}}
                    columns={[
                        {title: "Description", field: "description"},
                        {title: "Remark", field: "remark"},
                        {title: "Income", field: "income", type: "numeric"},

                    ]}
                    data={record.income.otherIncomes}
                    title='Other Income Means'
                />

            </CardContent>}
        </Card>

        {/*<Card>*/}
        {/*    <CardContent>*/}
        {/*        <Grid container spacing={2}>*/}
        {/*        {record.visitPhotos && record.visitPhotos.map(p=><Grid md={6} item>*/}
        {/*            <Card>*/}
        {/*                <CardMedia*/}
        {/*                    className={classes.media}*/}
        {/*                    image={p.filePath}*/}
        {/*                    title={p.title}*/}
        {/*                />*/}
        {/*                <CardContent>*/}
        {/*                    {p.title}*/}
        {/*                    {p.description}*/}
        {/*                </CardContent>*/}

        {/*            </Card>*/}
        {/*        </Grid>)}*/}
        {/*        </Grid>*/}
        {/*    </CardContent>*/}
        {/*</Card>*/}

        <Card>
            <CardContent>
        <ImagesWithLightBox photos={record.visitPhotos} />
            </CardContent>
        </Card>
    </div>
    </div>
}
export  default VisitSummery
