import React from 'react';

const FieldDisplay = ({label, value}) => {
    return (
        <div>
            {label}: <strong>{value}</strong>
        </div>
    );
};

export default FieldDisplay;
