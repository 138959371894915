import React, {useState} from 'react';
import {Button, FormTab, useDataProvider,useRedirect} from "react-admin";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from "@material-ui/core/FormGroup";
import CheckBox  from "@material-ui/core/Checkbox";
import FormControlLabel  from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {DatePicker} from "@material-ui/pickers";
import _ from 'lodash'
const AcceptApplication = ({record}) => {
    const dataProvider = useDataProvider();
    const [registrationId,setRegistrationId] = useState('')
    const [date,setDate] = useState(new Date().toString())
    const [name,setName] = useState('')
    const [processedBy,setProcessedBy] = useState('')
    const [fatherName,setFatherName] = useState('')
    const [phone,setPhone] = useState('')
    const [email,setEmail] = useState('')
    const [official,setOfficial] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };
    const redirect = useRedirect();

    const handleConfirm = () => {
        // useDataProvider
        const data = {..._.omit(record,'fullName'),official,application:record._id,registrationId,date,status:"Active",sponsor:{name,fatherName,address:{phones:[{number:phone}],email}},processedBy}
        dataProvider.create('Record',{data}).then(()=>{
            setDialogOpen(false)
            redirect('/Record')
        })
    };
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} label="Sponsor Found, Accept" />
            <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Accept {record.name}'s Application</DialogTitle>
                <DialogContent>

                    <FormGroup row >
                    <TextField
                        autoFocus
                        value={registrationId}
                        onChange={ev=>setRegistrationId(ev.target.value)}
                        label="Registration ID"
                        margin='normal'
                    />
                        {/*<TextField*/}
                        {/*    value={date}*/}
                        {/*    type="date"*/}
                        {/*    onChange={ev=>setDate(ev.target.value)}*/}
                        {/*    label="Date"*/}
                        {/*    margin='normal'*/}
                        {/*/>*/}
                        <DatePicker margin='normal' value={date} label="Date" onChange={ev=>setDate(ev.toString())} />
                    </FormGroup >
                    <Typography variant="h6" gutterBottom>Sponsor Info</Typography>
                    <FormGroup row >

                        <TextField
                            value={name}
                            onChange={ev=>setName(ev.target.value)}
                            label="First Name"
                            margin='normal'
                        />
                        <TextField
                            value={fatherName}
                            onChange={ev=>setFatherName(ev.target.value)}
                            label="Last Name"
                            margin='normal'
                        />
                    </FormGroup>

                    <FormGroup row>
                                        <TextField
                                            value={phone}
                                            onChange={ev=>setPhone(ev.target.value)}
                                            label="Phone"
                                            margin='normal'
                                        />
                                        <TextField
                                            value={email}
                                            onChange={ev=>setEmail(ev.target.value)}
                                            label="Email"
                                            margin='normal'
                                        />
                    </FormGroup>

                    <TextField
                        value={processedBy}
                        onChange={ev=>setProcessedBy(ev.target.value)}
                        label="Processed By"
                        margin='normal'
                    />
                    <FormControlLabel control={ <CheckBox checked={official} onChange={ev=>setOfficial(ev.target.checked)}/>} label="Offical">

                    </FormControlLabel>

                    <Button onClick={handleConfirm} label="Confirm"/>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AcceptApplication;
