import React from "react";
import {Layout} from 'react-admin';
import Menu from './menu';
import Sidebar from './sideBar'
import MyAppBar from "./myAppBar";



// const MyLayout = props => <Layout {...props} sidebar={MySidebar} />
// const MyMenu = (props) => <Menu {...props} />

const MyLayout = (props) => <Layout  {...props}  appBar={MyAppBar} menu={Menu} sidebar={Sidebar} />;

export default MyLayout;
