import React from 'react';
import {Button} from 'react-admin'
import RadioGroup from '@material-ui/core/RadioGroup';
import AcceptApplication from "./acceptApplicationDialog";
import Card from "@material-ui/core/Card";

// import DateField from '@material-ui/core/';


const ApplicationActions = ({ source, record = {}  }) => {
if(record.status ==='Accepted')
{
    return null
}

    return (
        <Card style={{marginTop:50}}>
            {/*Status*/}
            {/*<RadioGroup*/}
            {/*    className="select-all"*/}
            {/*    color="primary"*/}

            {/*/>*/}
            <AcceptApplication record={record}/>


        </Card>
    );
};

export default ApplicationActions;
