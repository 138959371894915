import {Sidebar} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import React from "react";

const useSidebarStyles = makeStyles(theme=> ({
    drawerPaper: {
        backgroundColor: theme.palette.primary.main,
        minHeight: '100vh',
        height: '100%',
        marginTop: 0,
        // color: 'black'
    },
}));

export default props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};

