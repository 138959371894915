export default  {
   // Application: `
   //   name
   //   fatherName
   //   `,
   createApplication: `
     name
     fatherName
     `,
    updateApplication: `
     name
     fatherName
     `,
    allApplications: `
     id
     photo
     name
     fullName
     createdBy
     modifiedBy
     fatherName
     date
     status
     `,

    // Record: `
    //  name
    //  fatherName
    //  `,
    createRecord: `
     name
     fatherName
     `,
    updateRecord: `
     name
     fatherName,
     fullName,
     registrationId
     `,
    allRecords: `
     name
     _id
     fatherName
     gFatherName
     fullName
     date
     registrationId
     photo
     official
     createdBy
     modifiedBy
     birthDate
     `,
 allVisits: `
     name
     _id
     fatherName
     gFatherName
     name
     date
     registrationId
     photo
     createdBy
     modifiedBy
     category
     `,
allPosts: `date
 title
 recordId
 type
 files {
  filePath
  title
 }
 body`,


}
